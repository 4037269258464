<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>AddForms</h1>
			</div>
			<div>
				<button class="btnprimary" @click="addForms()">Add Form</button>
			</div>
		</div>
		<div class="card shadow-2xl p-4 mt-4" v-for="(form, formIndex) in forms" :key="formIndex">
			<div>
				<div class="flex justify-between">
					<div class="flex">
						<div>
							<input v-if="form.toggleSourceInput" @blur="form.toggleSourceInput = false" type="text" class="inputboxstyling" v-model="form.formName" placeholder="FormName" />
							<h6 v-if="!form.toggleSourceInput" class="screenName">
								{{ form.formName }}
							</h6>
						</div>
						<div class="controlicons flex p-1 mt-1.5" @click="form.toggleSourceInput = true">
							<PencilIcon class="h-2.5 w-2.5" />
						</div>
					</div>
					<div>
						<button class="btnprimary" @click="addInputControlsForFormData(form)">Add</button>
					</div>
				</div>
				<div v-for="(inputControl, inputControlIndex) in form.inputControls" :key="inputControlIndex">
					<div class="flex">
						<div style="width: 50%" class="flex justify-between">
							<div class="w-1/2">
								<label class="controllabel font-normal text-sm mb-1">InputControls</label>
								<select class="inputboxstyling" v-model="inputControl.screenType">
									<option>TextBox</option>
									<option>TextArea</option>
									<option>Radio</option>
									<option>CheckBox</option>
									<option>Dropdown</option>
								</select>
							</div>

							<div class="flex flex-col justify-center items-center mt-2 w-1/2" v-if="inputControl.screenType">
								<label class="controllabel font-normal text-sm mb-1">Mandatory</label>
								<label class="switch mt-1">
									<input type="checkbox" v-model="inputControl.mandatory" />
									<span class="slider round"></span>
								</label>
							</div>
						</div>
						<div style="width: 50%" class="flex justify-between">
							<div v-if="inputControl.screenType">
								<label class="controllabel font-normal text-sm mb-1">label</label>
								<input type="text" class="inputboxstyling" v-model="inputControl.label" />
							</div>
							<div class="ml-3" v-if="inputControl.screenType == 'Radio' || inputControl.screenType == 'CheckBox' || inputControl.screenType == 'Dropdown'">
								<label class="controllabel font-normal text-sm mb-1">Option</label>
								<input type="text" class="inputboxstyling" v-model="inputControl.option" />
							</div>
							<div class="gap-1.5 mt-6 ml-3" v-if="inputControl.screenType == 'Radio' || inputControl.screenType == 'CheckBox' || inputControl.screenType == 'Dropdown'" @click="addLabelsforInputControls(form, inputControlIndex, inputControl.option)">
								<PlusIcon class="w-8 h-8" />
							</div>
						</div>
					</div>
					<div>
						<div class="optionscontainer border rounded-sm border-lightgrey mt-2.5 p-1.5 text-white flex justify-between bg-darkslategrey min-h-30" v-for="(singleoption, optionIndex) in form.inputControls[inputControlIndex].options" :key="singleoption">
							<div class="imgoptioncontainer">
								<div class="ml-2">
									{{ singleoption.option }}
								</div>
							</div>
							<div class="flex">
								<div @click="onOptionEdit(formIndex, inputControlIndex, optionIndex)">
									<PencilIcon class="h-5 w-5" />
								</div>

								<div @click="onOptionDelete(formIndex, inputControlIndex, optionIndex)">
									<TrashIcon class="h-5 w-5" />
								</div>
								<div @click="onOptionUpdate(formIndex, inputControlIndex, optionIndex)" v-if="hideUpdateIcon">
									<CheckCircleIcon class="w-5 h-5" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex justify-center mt-4">
					<button class="btnprimary" @click="save(formIndex, inputControlIndex)">Save</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.h-8 {
	color: grey;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	data(): any {
		return {
			forms: [],
			inputControls: [],
			hideUpdateIcon: false
		};
	},
	methods: {
		addForms(): any {
			this.forms.push({
				formName: 'Basic Details',
				createdBy: '',
				createdAt: '',
				updatedBy: '',
				updatedAt: '',
				inputControls: [],
				toggleSourceInput: false
			});
		},
		onOptionEdit(formIndex: any, inputControlIndex: any, optionIndex: any): any {
			this.hideUpdateIcon = true;
			this.forms[formIndex].inputControls[inputControlIndex].option = this.forms[formIndex].inputControls[inputControlIndex].options[optionIndex].option;
		},
		onOptionDelete(formIndex: any, inputControlIndex: any, optionIndex: any): any {
			this.forms[formIndex].inputControls[inputControlIndex].options.splice(optionIndex, 1);
		},
		onOptionUpdate(formIndex: any, inputControlIndex: any, optionIndex: any) {
			this.forms[formIndex].inputControls[inputControlIndex].options[optionIndex].option = this.forms[formIndex].inputControls[inputControlIndex].option;
		},
		addInputControlsForFormData(form: any): any {
			form.inputControls.push({
				screenType: '',
				options: [],
				label: '',
				mandatory: false
			});
		},
		addLabelsforInputControls(form: any, Index: any, optionData: any): any {
			form.inputControls[Index].options.push({ option: optionData });
			delete form.inputControls[Index].option;
		},
		save(formIndex: any) {
			console.log('save', this.forms[formIndex]);
		}
	}
});
</script>