<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>Processes</h1>
			</div>
			<div>
				<router-link :to="{ name: 'manageProcess' }" class="btnprimary">Add new</router-link>
			</div>
		</div>
		<div class="flex mt-4" v-if="processes.length > 0">
			<div class="row">
				<div>
					<table>
						<thead>
							<tr>
								<th class="cursor-pointer">Process name</th>
								<th class="cursor-pointer">Created </th>
								<th class="cursor-pointer">Action</th>
							</tr>
						</thead>
						<tbody v-if="processes.length > 0">
							<tr v-for="(process, processIndex) of processes" :key="processIndex">
								<td>{{ process.name }}</td>
									<td>{{ process.createdAt }}</td>
								<td>
									<div class="flex flex-row align-center">
										<div class="px-1 py-0 cursor-pointer"  @click="editProcess(process._id)">
										<img src="@/assets/editIcon.svg" class="h-5 w-5 mt-2" />
										</div>
										<div class="px-1 py-0 cursor-pointer"  @click="deleteProcesses(process)">
											<img src="@/assets/deleteIcon.svg" class="h-5 w-5 mt-2" />
										</div>
									</div>
								</td>
							
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment'
import { toast } from '@/main';

export default defineComponent({
	data(): any {
		return {
			processes: []
		};
	},
	methods: {
		async getProcesses() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/process`).then((res: any) => {
				console.log('config list', res.data);
				this.processes = res.data;
					this.processes.forEach((process: any) => {
					let now = moment(process.createdAt);
					process.createdAt = now.format('DD-MM-YYYY') ;
				});
			});
		},
		async deleteProcesses(sprocess: any) {
			await this.$http
				.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}/process/${sprocess._id}/delete`)
				.then((response: any) => {
					toast.info('Configuration Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getProcesses();
				})
				.catch((error: any) => {
					toast.error('Configuration  Not Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			console.log('config list', this.allWorkFlows);
		},
		editProcess(id: any) {
				this.$router.push({ name: 'editProcess',params: {processId: id }});
		}
	},
	mounted() {
		this.getProcesses();
	}
});
</script>