import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import addForm from "@/features/addForms.vue";
import manageConfiguration from "@/features/manageConfiguration.vue";
import manageWorkflow from "@/features/manageWorkflow.vue";
import configurations from "@/features/configurations.vue";
import workflows from "@/features/workflows.vue";
import processes from "@/features/processes.vue";
import manageProcess from "@/features/manageProcess.vue";
import tasks from "@/features/tasks.vue";
import manageTask from "@/features/manageTasks.vue";
import { adminNavigationGuard } from "./navGuard";
import  manageUserProcesses from "@/features/end-user/manageUserProcesses.vue"
import editTasksForEndUser from "@/features/end-user/editTasks.vue"
import orgTasks from "@/features/end-user/tasks.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/addForm",
        name: "addForm",
        meta: {
            title: "addForm",
            layout: defaultLayout,
        },
        component: addForm,
    },
    {
        path: "/configurations",
        name: "configurations",
        meta: {
            title: "configurations",
            layout: defaultLayout,
        },
        component: configurations,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/configurations/createconfiguration",
        name: "createConfiguration",
        meta: {
            title: "manage-configuration",
            layout: defaultLayout,
        },
        component: manageConfiguration,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
   
    {
        path: "/configurations/:configurationId/editconfiguration",
        name: "editConfiguration",
        meta: {
            title: "manage-configuration",
            layout: defaultLayout,
        },
        component: manageConfiguration,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/workflows",
        name: "workflows",
        meta: {
            title: "workflows",
            layout: defaultLayout,
        },
        component: workflows,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/workflow/createWorkflow",
        name: "createWorkflow",
        meta: {
            title: "workflowscreate",
            layout: defaultLayout,
        },
        component: manageWorkflow,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/workflow/editWorkflow/:workflowId",
        name: "editWorkflow",
        meta: {
            title: "workflowscreate",
            layout: defaultLayout,
        },
        component: manageWorkflow,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    // {
    //     path: "/workflow/manage",
    //     name: "manageWorkflow",
    //     meta: {
    //         title: "manage-workflow",
    //         layout: defaultLayout,
    //     },
    //     component: manageWorkflow,
    // },
    // {
    //     path: "/workflow/edit/:workflowId",
    //     name: "editWorkflow",
    //     meta: {
    //         title: "edit-workflow",
    //         layout: defaultLayout,
    //     },
    //     component: manageWorkflow,
    // },
    {
        path: "/processes",
        name: "processes",
        meta: {
            title: "processes",
            layout: defaultLayout,
        },
        component: processes,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/process/createProcess",
        name: "manageProcess",
        meta: {
            title: "manage-process",
            layout: defaultLayout,
        },
        component: manageProcess,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    {
        path: "/process/editProcess/:processId",
        name: "editProcess",
        meta: {
            title: "edit-process",
            layout: defaultLayout,
        },
        component: manageProcess,
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   }
    },
    // {
    //     path: "/tasks",
    //     name: "tasks",
    //     meta: {
    //         title: "tasks",
    //         layout: defaultLayout,
    //     },
    //     component: tasks,
    // },
    {
        path: "/tasks/manage",
        name: "manageTask",
        meta: {
            title: "manage-tasks",
            layout: defaultLayout,
        },
        component: manageTask,
    },
    {
        path: "/org/tasks",
        name: "org-tasks",
        meta: {
            title: "tasks",
            layout: defaultLayout,
        },
        component: orgTasks,
    },
    {
        path: "/task/edit/:taskId",
        name: "editTask",
        meta: {
            title: "edit-task",
            layout: defaultLayout,
        },
        component: manageTask,
    },
    {
        path: "/task/edit/:taskId",
        name: "editTask",
        meta: {
            title: "edit-task",
            layout: defaultLayout,
        },
        component: manageTask,
    },
    {
        path: "/org/task/edit/:taskId",
        name: "editTasksForEndUser",
        meta: {
            title: "editTasksForEndUser",
            layout: defaultLayout,
        },
        component: editTasksForEndUser,
    },
    // {
    //     path: "/org/manage/processes",
    //     name: "manage-orgprocesses",
    //     meta: {
    //         title: "manage-orgprocesses",
    //         layout: defaultLayout,
    //     },
    //     component: manageUserProcesses,
    // },
    {
        path: "/org/manage/processes/:departmentId",
        name: "manage-orgprocesses",
        meta: {
            title: "manage-orgprocesses",
            layout: defaultLayout,
        },
        component: manageUserProcesses,
    },
    {
        path: '/',
        name: 'default',
        meta: {
            title: "Dashboard",
            layout: defaultLayout,
        },
        // beforeEnter: (to, from, next) => {
        //     adminNavigationGuard(to, from, next)
        //   },
        redirect: { name: 'workflows' }
    },

];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title=`Workflow - ${to.meta.title}`
    store.state.isLoading = true;
    console.log('Router check triggered', to)
    if (to.path == '/workflows'|| to.path == '/') {
        console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        await normalAuthenticationCheck(next);
    } else {
        await normalAuthenticationCheck(next);
    }
    next();
});

router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});


async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if(!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_WORKFLOW_WEBAPP_URL}`;
    } else{
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if(!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else{
            next();
        }
    }
}



export default router;
