<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>Tasks</h1>
			</div>
			<!-- <div>
				<router-link :to="{ name: 'manageTask' }" class="btnprimary">Add new</router-link>
			</div> -->
		</div>
		<div class="flex mt-4" v-if="tasks.length > 0">
			<div class="row">
				<div>
					<table>
						<thead>
							<tr>
								<th class="cursor-pointer">Task number</th>
								<th class="cursor-pointer">Task Status</th>
								<th class="cursor-pointer">Created</th>
								<!-- <th class="cursor-pointer">Actions</th> -->
							</tr>
						</thead>
						<tbody v-if="tasks.length > 0">
							<tr v-for="(task, taskIndex) of tasks" :key="taskIndex">
								<td @click="editTask(task._id)" class="cursor-pointer">{{ task.taskName }}</td>
								<td>Open</td>
								<td>{{task.createdAt}}</td>
								<!-- <td class="child-action-col">
									<div class="flex flex-row align-center">
										<div class="px-1 py-0" @click="editTask(task._id)">
											<PencilAltIcon class="h-5 w-5" />
										</div>
										<div @click="deleteTeam(teamindex, team)">
											<TrashIcon class="h-5 w-5" />
										</div>
									</div>
								</td> -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
// import { TrashIcon, PencilAltIcon } from '@heroicons/vue/outline';

export default defineComponent({
	data(): any {
		return {
			tasks: []
		};
	},
	// components: {
	// 	PencilAltIcon,
	// 	TrashIcon
	// },
	methods: {
		async getTasks() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/tasks/getall`).then((res: any) => {
				console.log('config list', res.data);
				this.tasks = res.data;
				
			});
		},
		editTask(id: any) {
			this.$router.push({ name: 'editTasksForEndUser', params: { taskId: id } });
		}
	},
	mounted() {
		this.getTasks();
	}
});
</script>