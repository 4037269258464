<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>Create task</h1>
			</div>
		</div>
		<div class="card shadow-2xl p-4 mt-4">
			<div class="w-1/2 mt-4">
				<label class="controllabel font-normal text-sm mb-1">Please select process:</label>
				<select class="inputboxstyling" v-model="selectedProcess" @change="getProcessData($event)">
					<option>Please select one</option>
					<option v-for="process of processes" :value="process._id" :key="process._id">{{ process.name }}</option>
				</select>
			</div>
			<div class="flex justify-between w-100 mt-4">
				<div class="w-1/3" v-if="showControls">
					<label class="controllabel font-normal text-sm mb-1">Status:</label>
					<select class="inputboxstyling" v-model="selectedStatus">
						<option v-for="(status,statusIndex) in processData.workFlowData.statuses" :value="statusIndex" :key="statusIndex">{{ status.label }}</option>
					</select>
				</div>
				<div class="w-1/3 ml-5">
					<label class="controllabel font-normal text-sm mb-1">Created by:</label>
					<select class="inputboxstyling">
						<option>Please select one</option>
						<option v-for="team in teams" v-bind:value="team" :key="team._id">{{ team.teamName }}</option>
					</select>
				</div>
				<div class="w-1/3 ml-5">
					<label class="controllabel font-normal text-sm mb-1">Assignee:</label>
					<select class="inputboxstyling">
						<option>Please select one</option>
						<option v-for="team in teams" :value="team" :key="team._id">{{ team.teamName }}</option>
					</select>
				</div>
			</div>
			<div class="flex justify-between w-100 mt-4" v-if="showControls">
				<div class="controls flex" v-for="(control, controlIndex) in processData.configurationData.controls" :key="controlIndex">
					<div v-if="control.controlType == 'TextBox'">
						<label class="controllabel font-normal text-sm mb-1">{{control.label}}:</label>
						<input type="text" class="inputboxstyling" v-model="control.value"/>
					</div>
                    <div v-else-if="control.controlType == 'TextArea'">
						<label class="controllabel font-normal text-sm mb-1">{{control.label}}:</label>
						<textarea rows="4" cols="50" style="border:1px solid gray;" v-model="control.value"/>
					</div>
                    <div v-else-if="control.controlType == 'Dropdown'">
						<label class="controllabel font-normal text-sm mb-1">{{control.label}}:</label>
                        <select class="inputboxstyling" v-model="control.value">
                            <option>Please select one</option>
                            <option v-for="(option,optionIndex) in control.options" :key="optionIndex">{{ option.option }}</option>
                        </select>
					</div>
				</div>
			</div>
			<div class="flex justify-center mt-5">
				<button class="btnprimary" @click="generaTask()">Generate</button>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
	data(): any {
		return {
			processes: [],
			selectedProcess: '',
            processData: '',
            showControls : false,
			selectedStatus:'',
			pageType: 'create',
		};
	},
	methods: {
		async getProcesses() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/process`).then((res: any) => {
				console.log('config list', res.data);
				this.processes = res.data;
			});
		},
		async getProcessData(event:any) {
			let a=event.target.value
			console.log("sss",event.target.value)
			this.showControls = false;
			this.processData = '';
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/${a}`).then(async (res: any) => {
				this.processData = res.data[0];
				
				this.processData.workFlowData = await this.getWorkflowData(this.processData.mappedWorkflow);
				console.log("ssss",this.processData.workFlowData)
				this.processData.configurationData = await this.getConfigurationData(this.processData.workFlowData.linked);
                this.showControls = true;
				console.log('process data',this.processData);
			});
		},
		async getWorkflowData(id: any) {
			// console.log(`http://localhost:3040/services/workflow/master/${id}`)
			return await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${id}`).then((res: any) => {
				console.log('Workflow data', res.data);
				return res.data[0];
			});
		},
		async getConfigurationData(id: any) {
			return await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${id}`).then((res: any) => {
				console.log('configuration data', res.data);
				return res.data[0];
			});
		},
		async generaTask(){
			this.processData.workFlowData.statuses[this.selectedStatus].selected = true;
			console.log('final data',this.processData);
			this.processData.taskNumber = `T-001`;
			delete this.processData._id;
			await this.$http.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/tasks/create`, this.processData).then(() => {
				this.$router.back();
			});
		},
		async getTaskData(id:any){
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/task/${id}`).then((res: any) => {
				console.log('Workflow data', res.data);
				this.processData = res.data[0];
				this.showControls = true;
			});
		},	
		async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teams = res.data;
				// for (var i = 0; i < this.teamsarray.length; i++) {
				// 	this.teams.push(this.teamsarray[i].teamName);
				// }
			});
			console.log("teams", this.teams)
		}
	},
	mounted() {
		this. getAllTeams()
		if(this.$route.params.taskId) {
			this.pageType =  'edit';
			this.getTaskData(this.$route.params.taskId);
		}
		else  this.pageType =  'create';
		this.getProcesses();
	}
});
</script>