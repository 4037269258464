<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div class="flex cursor-pointer">
				<div><img src="@/assets/arrowLeftNavigation.svg" class="w-4 h-4 mr-4 mt-1" @click="backtoTasks()" /></div>
				<h1>{{ task.taskName }}</h1>
			</div>
		</div>
		<div class="card mt-4">
			<div class="p-3 flex flex-row gap-1">
				<div class="w-3/4">
					<div>
						<div class="flex cursor-pointer">
							<!-- <div><img src="@/assets/arrowLeftNavigation.svg" class="w-6 h-6 mr-4" @click="backtocourseEdit()" /></div> -->
							<h1>Description</h1>
						</div>
						<div class="border-b border-solid mt-2 border-lightgrey"></div>
						<p class="mt-4 max-h-42 overflow">
							{{ task.description }}
						</p>
					</div>

					<div class="mt-5">
						<div class="header flex items-center justify-between">
							<div class="flex cursor-pointer">
								<h1 class="ml-2">Attachments</h1>
							</div>
							<div>
								<div class="buttonposition flex justify-center items-center mx-0">
									<button class="btnprimary m-2" @click="addDocuments()">Attach</button>
								</div>
							</div>
						</div>
						<div class="border-b border-solid border-lightgrey"></div>
						
						<div class="flex  mt-2 gap-4 flex-wrap">
							
							<div v-for="n in task.documents" :key="n">
								
								<div class="pdf w-full">
										<a :href="n.documentUrl" class="flex w-full flex-row  items-center flex-wrap">
											<img src="@/assets/pdfs.svg" alt=""  />
									<p class="text-xs ml-1">{{fileName(n.documentUrl)}}</p>
										</a>
								</div>
							</div>
						</div>
					</div>

					<!-- <div class="mt-5">
						<div class="header flex items-center justify-between">
							<h1 class="ml-2">Sub Tasks</h1>
							<div>
								<div class="buttonposition flex justify-center items-center mx-0">
									<button class="btnprimary m-2" @click="addScreen()">Tasks</button>
								</div>
							</div>
						</div>
						<div class="border-b border-solid border-lightgrey"></div>
						<div class="mt-2">
							<div>
								<table>
									<tr v-for="n in 3" :key="n">
										<td>
											<div class="header flex items-center justify-between">
												<p class="ml-2">Task-{{ n }}</p>
												<div>
													<div class="buttonposition flex justify-center items-center">
														<div>
															<div class="priority"></div>
															<div class="priority mt-2"></div>
														</div>
														<select class="inputboxstyling ml-2">
															<option>Open</option>
															<option>Close</option>
														</select>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div> -->

					<div class="mt-5">
						<div class="header flex items-center justify-between">
							<!-- <div class="flex cursor-pointer"> -->
							<!-- <div><img src="@/assets/arrowLeftNavigation.svg" class="w-6 h-6 mr-4" @click="backtocourseEdit()" /></div> -->
							<h1 class="ml-2">Links</h1>
							<!-- </div> -->
							<div>
								<div class="buttonposition flex justify-center items-center mx-0">
									<button class="btnprimary m-2"  @click="showTaskModal =true">Links</button>
								</div>
							</div>
						</div>
						<div class="border-b border-solid border-lightgrey"></div>
						<table>
							<tr v-for="link in links" :key="link">
								<!-- <a href="https://www.w3schools.com/">{{link.linkUrl}}</a> -->
								<td><a :href="link.linkUrl" class="cursor-pointer">{{link.linkUrl}}</a></td>
							</tr>
						</table>
					</div>

					<div class="mt-5">
						<h1>Activity</h1>
						<hr />

						<div class="flex flex-1 flex-wrap tabs-boxed cursor-pointer mt-2">
							<div class="flex-auto text-center a" v-for="(singlenav, navIndex) in navBarheading" :key="singlenav.key">
								<a class="tab text-sm font-bold" v-on:click="toggleTabs(navIndex)" :class="{ correct: openTab == navIndex }">
									{{ singlenav }}
								</a>
							</div>
							
						</div>

						<div v-if="openTab == 0" class="mt-4">
							<textarea name="" class="inputboxstyling" placeholder="Add a Comment" cols="3" rows="3" v-model="comments"></textarea>
							<div v-if="v$.comments.$error" class="text-red-500">
																<div class="error-text" v-if="v$.comments.required.$invalid">Required</div>
															</div>
							<div class="flex justify-end">
								<button  class="btnprimary mt-2 mr-2" @click="updateComments()">Add</button>
								<button  class="btnprimary mt-2" @click="clearComments()">Clear</button>
							</div>
							<div class="comments mt-4" v-for="comment in task.comments" :key="comment">
								<div class="singlecomment p-2">
									{{ comment.comments }}
								</div>
							</div>
							
						</div>
					</div>
				</div>

				<div class="w-1/4 taskdetails  px-4  ">
				<h1 class="font py-0.5">Ticket Details</h1>
				<div class="border-b border-solid border-lightgrey mt-1 rounded "></div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Status:</label>
						<select class="inputboxstyling">
							<option>Open</option>
							<option>Close</option>
						</select>
					</div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Ticket Type:</label>
						<p class="mt-1 text-xs font-normal">Task</p>
					</div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Department:</label>
						<p class="mt-1 text-xs font-normal">IT</p>
					</div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Assigned To:</label>
						<p class="mt-1 text-xs font-normal">Nikhil</p>
					</div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Priority</label>
						<p class="mt-1 text-xs font-normal">Critical</p>
					</div>
					<div class="mt-2">
						<label class="controllabel text-xs font-semibold mb-1">Created At</label>
						<p class="mt-1 text-xs font-normal">{{task.createdAt}}</p>
					</div>
				</div>

					<div class="userPopup">
							<div v-if="showTaskModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-center items-center flex">
								<div class="relative my-6 w-full max-w-xl">
									<!--content-->
									<div class="border-0 rounded relative flex flex-col w-full bg-white">
										<!--header-->
										<div class="flex items-start justify-between p-5 rounded">
											<h1>Add Links</h1>
											<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showTaskModal=false">
												<!-- <XIcon class="h-5 w-5" /> -->
												<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
											</button>
										</div>
										<!-- <div class="border-b border-solid border-ightgrey"></div> -->
										<div class="p-2">
											<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer" >
												<div>
													<hr class="border-t-2" />
													<div class="p-4 flex flex-col mt-2">
													

														<div v-for="link in links" :key="link" class="w-100 flex  ml-2">
																<div class="w-4 mt-2">
															<img src="@/assets/plus.svg" class="h-5 w-5 cursor-pointer mt-2"  @click="addLink()"/>
														</div>
																<input type="text" class="inputboxstyling mt-2 w-96 ml-2" v-model="link.linkUrl">
															<!-- {{link.linkUrl}} -->
															
														</div>

														
														</div>
													
													<div class="flex justify-center">
														<button class="btnprimary mb-4" @click="saveLinks()" v-if="!updateButton">Save</button>
														<!-- <button class="btnprimary mb-4" @click="updateControl(controlIndex)" v-if="updateButton">Update</button> -->
													</div>
													
												</div>
										
											
											
											
											</div>

											
										</div>
									</div>
								</div>
							</div>
							<div v-if="showTaskModal" class="opacity-40 fixed inset-0 z-50 bg-black"></div>
						</div>
						<div class="importpopup" v-if="newImportModal">
			<div  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index  justify-center items-center flex">
					<div class="relative my-6  max-w-xl">
						<!--content-->
						<div class="border-0 rounded  relative flex flex-col w-full bg-white ">
						<!--header-->
						<div class="flex items-start justify-between p-5">
							<h1>Import files</h1>
							<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="newImportModal =!newImportModal">
								<!-- <XIcon class="h-5 w-5" /> -->
								<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
							</button>
						</div>
								<div class="border-b border-solid border-lightgrey">
							</div>
						<!--body-->
						<div class="relative p-6 flex-auto">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full">
									<div class="col-md-6 w-6/12 p-2">
									
										<label htmlFor="upload" class="controllabel">Select File</label>
										<input  type="file" id="upload" ref="element"    />
									</div>
								</div>
							</div>
						</div>

						<div class="p-2 flex justify-end">
							<button class="btnprimary" @click="saveDocuments()">Save</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="newImportModal" class="opacity-40 fixed inset-0 z-40 bg-black"></div>
		</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.pdf{
	border:1px solid #e9e9e9;
	width:240px;
	padding: 4px
}

.z-index{
	z-index: 999999;
}
.py-1{
	height: 410px;
}
.font{
	font-size: 16px;
}
.taskdetails {
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	border-bottom: none
}
/* select {
	background-position: right 1px center;
	background-position-y: 13px;
} */
.priority {
	border: 2px solid #ff0000;
	background-color: red;
	width: 52px;
	height: 4px;
	border-radius: 2px;
}
.m-2 {
	background-color: #ffffff;
	border: 1px solid #4d4d4d;
	color: #4d4d4d;
	padding: 8px;
}
.tab {
	width: 100%;
	border-right: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-weight: 600;
	font-size: 14px;
	line-height: 14px;
	background-color: #ffffff;

	color: #4d4d4d;
}
.flexPosition::after {
	content: '';
	flex: auto;
	flex-basis: 285px;
	flex-grow: 0;
}

.iconscolor {
	color: rgba(31, 41, 55, 0.5);
}
.correct {
	width: 100%;
	background: rgba(21, 42, 67, 0.1);
	border: 1px solid rgba(21, 42, 67, 0.5) !important;
	color: #152a43;
}
.tabs-boxed :first-child a
 {
	border-left: 1px solid #e9e9e9;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.tabs-boxed :last-child a
 {
	border-left: 1px solid #e9e9e9;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.mr-2 {
	border-right: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	border-right: 1px solid #e9e9e9;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.tabs-boxed {
	width: 100%;
	border-radius: 4px;
	background-color: transparent;
}
.overflow {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}
.singlecomment {
	border: 1px solid #e9e9e9;
	height: 60px;
	margin-top: 10px;
}

table {
	box-shadow: none !important;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
}
</style>
<script lang="ts">
import router from '@/router';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';
import { toast } from '../../main';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			configData: [],
			showPreview: false,
			singleconfig: [],
			links:[{linkUrl:''}],
			openTab: '',
			comments: '',
			task: [],
			documentUrl:'',
			showTaskModal:false,
			newImportModal:false,
			navBarheading: ['Comments', 'Work Log', 'All']
		};
	},
	
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	
		validations() {
		return {
		comments:{required}
		}
		},
	methods: {
		addLink(){
			this.links.push({linkUrl:""})
		},
		clearComments(){
			this.comments=''
		},
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			this.filteredCourses = [];
		},
		addDocuments(){
			this.newImportModal=true
		},
		 fileName  (url: any) { 
			console.log("dddd",url)
			return url.substring(url.lastIndexOf('/') + 1)
			},
		async saveDocuments(){
			console.log("this.$refs",this.$refs)
			await this.sendData(this.$refs.element.files[0])
			console.log("this.documentUrl",this.documentUrl)
			let a={documentUrl:this.documentUrl}
			this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/task/${this.$route.params.taskId}/update?documents`, a)
				.then((response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.newImportModal=false
		},
		backtoTasks(){
			this.$router.push({name:'tasks'})
		
	},
		async getSingleTask() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/org/task/${this.$route.params.taskId}`).then((res: any) => {
				this.task = res.data[0];
				this.links= res.data[0].link
				console.log("ssss",this.task)
			});
		},

		saveLinks(){
			this.showTaskModal=false
			this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/task/${this.$route.params.taskId}/update?links`, this.links)
				.then((response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.comments =''
		
		
		},
		updateComments() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				this.v$.$reset();
			console.log('userInfo', this.userInfo);
			let a = { user: this.userInfo.name, comments: this.comments };

			this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/task/${this.$route.params.taskId}/update?comments`, a)
				.then((response: any) => {
					if (response.status == 200) {
						toast.info(`Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					this.getSingleTask();
				})
				.catch((error: any) => {
					toast.error(`Save failed with ${error}`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.comments =''
		}
		},
			async uploadImage (data:any) {
						return new Promise(resolve => {
						const reader:any = new FileReader()
						reader.onload = function () {
						resolve(reader.result.replace('data:', '')
						.replace(/^.+,/, '')) 
						}
						reader.readAsDataURL(data)
						})
					},
			 async sendData(data:any){
				this.spinnerLoading =true
					// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
					const imageFile = await this.uploadImage(data)
					let a={image:imageFile,name:data.name}
					try {
					await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/file/upload`, a)
						// .post(`http://localhost:4030/services/filemanagement/bucket/file/upload`, a)
						.then((response: any) => {
					if (response.status == 200) {
							console.log(response.data)
							this.documentUrl=response.data
							this.spinnerLoading=false
						} 
					else {
							console.log('response status', response.status);
						}
					})
					} catch (err) {
					console.error(err);
					}
			},
	},
	mounted() {
		this.getSingleTask();
		this.toggleTabs(0);
	}
});


</script>