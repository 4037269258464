<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>Process</h1>
			</div>
		</div>
		<div class="card justify-center items-center flex mt-4">
			<div class="w-full">
				<!--content-->
				<div class="flex flex-col w-full bg-white  min-h-320 ">
					<!--header-->
					<div class="flex p-5 border-b border-solid border-lightgrey rounded-t">
						<div class="formcontainer flex mr-2">
							<h1>Create Process</h1>
						</div>
					</div>
					<div class="p-2 mt-4">
						<div class="flex justify-between w-100 gap-3">
						<div class="w-1/2">
							<label class="controllabel font-normal text-sm ">Process</label>
							<input type="text" v-model="process.name" class="inputboxstyling"
							:class="{
										'is-invalid': v$.process.name.$error
									}" />
								<div v-if="v$.process.name.$error" class="text-red-500">
									<div class="error-text" v-if="v$.process.name.required.$invalid">Required</div>
								</div>
						</div>
						
							<div class="w-1/2">
								<label class="controllabel font-normal text-sm ">Please select workflow to proceed:</label>
								<select class="inputboxstyling" v-model="process.mappedWorkflow" :class="{
										'is-invalid': v$.process.mappedWorkflow.$error
									}">
									<option>Please select one</option>
									<option v-for="workflow in workflows" :value="workflow._id" :key="workflow._id">{{ workflow.workFlowName }}</option>
								</select>
								<div v-if="v$.process.mappedWorkflow.$error" class="text-red-500">
									<div class="error-text" v-if="v$.process.mappedWorkflow.required.$invalid">Required</div>
								</div>
							</div>
							</div>
						<div class="flex justify-between w-100 gap-3">
							<!-- <div class="w-1/2 mr-5">
								<label class="controllabel font-normal text-sm mb-1">Please select workflow to proceed:</label>
								<select class="inputboxstyling" v-model="process.mappedWorkflow">
									<option>Please select one</option>
									<option v-for="workflow in workflows" :value="workflow._id" :key="workflow._id">{{ workflow.workFlowName }}</option>
								</select>
							</div> -->
							<div class="w-1/2" >
								<label class="controllabel font-normal text-sm mb-1">Target Audience</label>
								<select class="inputboxstyling" v-model="process.targetAudience" @change="changeTarget(process)"  :class="{
										'is-invalid': v$.process.targetAudience.$error
									}">
									<option value="10501">Everyone</option>
									<option value="10502">Team(s)</option>
									
									<!-- <option value="10503">User(s)</option> -->
								</select>
								<!-- {{v$.process.targetAudience.$error}} -->
								<div v-if="v$.process.targetAudience.$error" class="text-red-500">
									<div class="error-text" v-if="v$.process.targetAudience.required.$invalid">Required</div>
								</div>
							</div>
							<!-- {{teamsarray}} -->
								<div class="w-1/2" >
								<label class="controllabel font-normal text-sm mb-1">End user departments:</label>
								<!-- <Multiselect
									v-model="process.endUsersDepartment"
									mode="tags"
									:options="teamsarray"
									class="inputboxstyling z-20"
									:class="{
										'is-invalid': v$.process.endUsersDepartment.$error
									}"
								/> -->
								<Multiselect
									v-model="process.endUsersDepartment"
									:options="teamsarray"
									:showNoOptions="false"
									:multiple="true"
									:close-on-select="true"
									:clear-on-select="true"
									:hide-selected="true"
									open-direction="bottom"
									label="label"
									track-by="value"  
									:searchable="true"
									:hideArrow="false"
									placeholder="Departments"
									class="custom-multiselect userSelect"
									:class="{
									'is-invalid borderRed': v$.process.endUsersDepartment.$error
									}"
								>
								</Multiselect>
								<div v-if="v$.process.endUsersDepartment.$error" class="text-red-500">
									<div class="error-text" v-if="v$.process.endUsersDepartment.required.$invalid">Required</div>
								</div>

								<!-- {{v$.process.endUsersDepartment.$error}} -->
								<!-- <select class="inputboxstyling" v-model="process.endUsersDepartment">
									<option>Please select one</option>
									<option v-for="team in teams" v-bind:value="team" :key="team._id">{{ team.teamName }}</option>
								</select> -->
							</div>
						</div>
						<div class="flex justify-between w-100">
						
							<div class="w-1/2">
								<label class="controllabel font-normal text-sm mb-1">Task team / owner:</label>
								
								<select class="inputboxstyling" v-model="process.taskOwnersDepartment"
											:class="{
										'is-invalid': v$.process.taskOwnersDepartment.$error
									}">
									<option>Please select one</option>
									<option v-for="team in teams" :value="team" :key="team._id">{{ team.teamName }}</option>
								</select>
									<div v-if="v$.process.taskOwnersDepartment.$error" class="text-red-500">
									<div class="error-text" v-if="v$.process.taskOwnersDepartment.required.$invalid">Required</div>
								</div>
							</div>
						</div>
						<div class="flex items-end justify-end mt-5">
							<button class="btnprimary" @click="saveProcess(process)" v-if="pageType == 'create'">Save</button>
							<button class="btnprimary" @click="updateProcess(process)" v-else>Update</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.multiselect-tag {
  font-size: 12px;
  font-weight: 600;
}
.multiselect-tag.is-disabled {
  background-color: #059669 !important;
}
.multiselect.is-disabled {
  background-color: white !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
// import Multiselect from '@vueform/multiselect';
import Multiselect from 'vue-multiselect';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import { toast } from '../main';
import moment from 'moment';
import { requiredIf } from '@vuelidate/validators';
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			teams: [],
			workflows: [],
			teamsarray:[],
			allworkflows:[],
			pageType: '',
			statusCodeId:'',
			process: {
				name: '',
				mappedWorkflow: '',
				targetAudience:'',
				endUsersDepartment: [],
				taskOwnersDepartment: ''
			}
		};
	},
	validations(smIndex: any) {
		return {
			process: {
				name: { required },
				mappedWorkflow:  { required },
				targetAudience:{ required },
				endUsersDepartment: {
					required: requiredIf(() => {
						return this.statusCodeId == 10502;
					})
				},
				taskOwnersDepartment: { required },
			}
		};
	},
	components: { Multiselect },
	methods: {
			changeTarget(process: any) {
			console.log('sss', process);
			this.statusCodeId = process.targetAudience
		},
		async getWorkflows() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master`).then((res: any) => {
				console.log('config list', res.data);
				this.workflows = res.data;
				this.allworkflows=this.workflows.filter((SingleWorkflow:any)=>{
					return SingleWorkflow.linked
				})
			});
		},
		async getSingleProcess() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/process/${this.$route.params.processId}`).then((res: any) => {
				this.process = res.data[0];
				
			});
		},
		async saveProcess(sProcess: any) {

				this.v$.$touch();
			if (!this.v$.$invalid) {
			let now = moment();
			sProcess.createdAt = now.format();

			if(sProcess.targetAudience=='10501'){
				sProcess.endUsersDepartment=this.teamsarray
			}
			let departments:any=[]
			// this.teams.forEach((team:any)=>{
				for(var i=0;i<sProcess.endUsersDepartment.length;i++){
					departments.push(sProcess.endUsersDepartment[i].value)
				}
				
			// })
			console.log("ddd",departments)
			let payload: any = sProcess;
			payload.endUsersDepartment=departments
			console.log("payload",payload)
			console.log('payload', payload);
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/process/create`, payload)
				.then((res: any) => {
					if (res.status == 200) {
						toast.info('Saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.$router.back();
				});
			}
		},
		async updateProcess(sProcess: any) {
				this.v$.$touch();
			if (!this.v$.$invalid) {
			let now = moment();
			this.process.updatedAt = now.format();
			delete this.process._id;
			console.log('save', this.config);
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/process/${this.$route.params.processId}/update`, this.process)
				.then((res: any) => {
					if (res.status == 200) {
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			this.$router.back();
			}
		},
		async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teams = res.data;
				for (var i = 0; i < this.teams.length; i++) {
					this.teamsarray.push({label: this.teams[i].teamName, value: this.teams[i]._id});
				}
			});
		}
	},
	mounted() {
		this.getAllTeams();
		this.getWorkflows();
		this.pageType = this.$route.fullPath.includes('createProcess') ? 'create' : 'edit';
		this.data = true;
		if (this.pageType == 'edit') {
			this.getSingleProcess();
		}
	}
});
</script>