<template>
	<div class="maincontainer py-8 px-4 w-full">
		<div class="flex justify-between">
			<div>
				<h1>Configurations</h1>
			</div>
			<div class="flex">
				<div>
					<router-link :to="{ name: 'createConfiguration' }" class="btnprimary">Add new</router-link>
				</div>
				<button class="filterButton cursor-pointer ml-3 flex" @click="filterOpen">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="flex mt-4" v-if="configData.length > 0">
			<div class="row">
				<div>
					<table>
						<thead>
							<tr>
								<th class="cursor-pointer" @click="sort('name')">
									<div class="flex items-center">
										<div>Configuration name</div>
										<div class="sort cursor-pointer" :class="className == 'name' ? classSort : 'opacity-50'"></div>
									</div>
								</th>
								<th class="cursor-pointer" @click="sort('createdAt')">
									<div class="flex items-center">
										<div>Created</div>
										<div class="sort cursor-pointer" :class="className == 'createdAt' ? classSort : 'opacity-50'"></div>
									</div>
								</th>
								<th class="cursor-pointer">
									<div class="flex items-center pl-5">
										<div>Actions</div>
									</div>
								</th>
							</tr>
							<tr v-if="showFilters">
								<td class="searchRow">
									<input type="text" class="inputboxstyling" v-model="filterDummy.name"/>
								</td>
								<td class="searchRow">
									<input type="text" class="inputboxstyling" v-model="filterDummy.createdAt"/>
								</td>
								<td></td>
							</tr>
						</thead>
						
						<tbody v-if="configData.length > 0">
							<tr v-for="(config, configindex) in sortedData" :key="configindex">
								<td  class="cursor-pointer">{{ config.name }}</td>
								<td>{{ config.createdAt }}</td>
								<td class="child-action-col">
									<div class="flex flex-row align-center">
										<div class="px-1 py-0 cursor-pointer"  @click="editConfiguration(config._id)">
										<img src="@/assets/editIcon.svg" class="h-5 w-5 mt-2" />
										</div>
										<div class="px-1 py-0 cursor-pointer"  @click="deleteConfiguration(config)">
											<img src="@/assets/deleteIcon.svg" class="h-5 w-5 mt-2" />
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="Popup w-full" v-if="showPreview">
			<div class="fixed inset-0 z-50 justify-center items-center flex">
				<div class="relative w-4/6 my-6 mx-auto max-w-4xl">
					<div class="popup border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<div>
							<div class="p-2">
								<div class="formcontainer flex mr-2">
									<h1>Create Configuration</h1>
								</div>
							</div>
							<div>
								<div class="statuscontainer p-2">
									<div class="formcontainer flex flex-col">
										<div class="row flex flex-row w-full">
											<div class="col-md-6 w-6/12 p-2">
												<label class="controllabel">Configuration</label>
												<p>{{ configDatas.name }}</p>
											</div>
											<div class="col-md-6 w-6/12 p-2 mt-6 flex flex-row-reverse">
												<button class="btnprimary" @click="editConfig(configDatas._id)">editConfig</button>
											</div>
										</div>
									</div>
									<hr />
								</div>
							</div>
							<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer" v-for="(control, controlIndex) in configDatas.controls" :key="controlIndex">
								<div class="p-2">
									<div>
										<div class="controlMetaData flex mt-2">
											<div class="w-1/2">
												<label class="controllabel font-normal text-sm mb-1">{{ control.label }}</label>
												<input type="text" class="inputboxstyling" v-if="control.controlType == 'TextBox' || control.controlType == 'TextArea'" />
											</div>
										</div>
										<div class="w-1/2" v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox'">
											<div class="min-h-30" v-for="option in configDatas.controls[controlIndex].options" :key="option">
												<input type="radio" v-if="control.controlType == 'Radio'" />
												<input type="checkbox" v-if="control.controlType == 'CheckBox'" />

												<label class="ml-2 mb-2"> {{ option.option }}</label>
											</div>
										</div>
										<div v-if="control.controlType == 'Dropdown'" class="w-1/2">
											<select class="inputboxstyling">
												<option v-for="option in configDatas.controls[controlIndex].options" :key="option">
													{{ option.option }}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="flex justify-end mb-4 items-end mr-4">
								<button class="btnprimary" @click="close()">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="showPreview" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../main';
import moment from 'moment';

export default defineComponent({
	data(): any {
		return {
			configData: [],
			singleConfiguration: {},
			showPreview: false,
			configDatas: [],
			filterDummy:{},
			classSort: '',
            className: '',
            pageType: '',
			currentSort: 'name',
            currentSortDir: 'asc',
			pageSize: 20,
            currentPage: 1,
			showFilters: false,
		};
	},
	computed: {
        sortedData: function (): any {
            return [...this.configData]
                .filter((row:any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
							// console.log("akwubd",row[key])
							if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
								return false;
							}	
                        }                    
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
	methods: {
		sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
		filterOpen() {
            this.showFilters = !this.showFilters;
             this.filterDummy={}
            if (this.showFilters == true) {
                this.dummyWorkFlows = this.allWorkFlows;
            } else {
                this.allWorkFlows = this.dummyWorkFlows;
            }
        },
		close() {
			this.showPreview = false;
			this.$router.push({ name: 'configurations' });
		},
		editConfiguration(id: any) {
			this.showPreview = true;
			this.configDetails = false;
			this.configDatas = this.configData.find((workflow: any) => {
				return workflow._id == id;
			});
			//  this.$router.push({ name: 'editConfiguration',params: { configurationId: this.singleConfiguration._id }});
		},
		editConfig(id: any) {
			this.$router.push({ name: 'editConfiguration', params: { configurationId: id } });
		},
		async getConfigurations() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master`).then((res: any) => {
				console.log('config list', res.data);
				this.configData = res.data;
				this.configData.forEach((singleConfiguration: any) => {
					if (singleConfiguration.createdAt) {
						let now = moment(singleConfiguration.createdAt);
						singleConfiguration.createdAt = now.format('DD-MM-YYYY');
					}
				});
			});
		},
		async deleteConfiguration(Configuration: any) {
			await this.$http
				.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master/${Configuration._id}/delete`)
				.then((response: any) => {
					toast.info('Configuration Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getConfigurations();
				})
				.catch((error: any) => {
					toast.error('Configuration  Not Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			console.log('config list', this.allWorkFlows);
		}
	},
	mounted() {
		this.getConfigurations();
	}
});
</script>
