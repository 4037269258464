<template>
	<div class="maincontainer py-6 px-4">
		<div class="flex justify-between items-center">
			<div>
				<h1>Workflow</h1>
			</div>
			<div class="flex">
				<button class="btnprimary flex justify-center items-center cursor-pointer" @click="addWorkflow()">
					<!-- <img class="h-3.5 w-4 mr-1" src="@/assets/plus.svg"/> -->
					Add
				</button>
				<button class="filterButton cursor-pointer ml-3 flex" @click="filterOpen">
                    Filters
                    <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                </button>
			</div>
		</div>
		
		<div class="flex mt-2">
			<div class="row" >
				<div>
					<table class="mytable">
						<thead>
							<tr>
								<th class="cursor-pointer" @click="sort('workFlowName')">
									<div class="flex items-center">
										<div>Workflow Name</div>
										<div class="sort cursor-pointer" :class="className == 'workFlowName' ? classSort : 'opacity-50'"></div>
									</div>
								</th>
								<th class="cursor-pointer" @click="sort('configuration')">
									<div class="flex items-center">
										<div>Configuration</div>
										<div class="sort cursor-pointer" :class="className == 'configuration' ? classSort : 'opacity-50'"></div>
									</div>
								</th>
								<th class="cursor-pointer" @click="sort('createdAt')">
									<div class="flex items-center">
										<div>Created On</div>
										<div class="sort cursor-pointer" :class="className == 'createdAt' ? classSort : 'opacity-50'"></div>
									</div>
								</th>
								<th class="cursor-pointer">
									<div class="flex items-center">
										<div>Actions</div>
									</div>
								</th>
							</tr>
							<tr v-if="showFilters">
								<td class="searchRow">
									<input type="text" class="inputboxstyling" v-model="filterDummy.workFlowName"/>
								</td>
								<td class="searchRow">
									<input type="text" class="inputboxstyling" v-model="filterDummy.configuration"/>
								</td>
								<td class="searchRow">
									<input type="text" class="inputboxstyling" v-model="filterDummy.createdAt" />
								</td>
								<td></td>
							</tr>	
						</thead>
						
						<tbody>
							<tr class="cursor-pointer" v-for="workflow in sortedData" :key="workflow._id">
								<td  @click="editStatus(workflow._id)">{{ workflow.workFlowName }}</td>
								<td><img src="@/assets/link.svg" class="h-5 w-6 " @click="linkPopup(workflow._id)"/></td>
								<td  @click="editStatus(workflow._id)"><div class="text-left">{{ workflow.createdAt }}</div></td>
								<td  @click="editStatus(workflow._id)">
									<!-- <div @click="tablepopup(workflow._id)" class="px-1 py-0 cursor-pointer">
										<img src="@/assets/editIcon.svg" class="h-5 w-5 " />
									</div> -->
									<div @click="deleteWorkFlow(workflow)" class="ml-3 py-0 cursor-pointer">
										<img src="@/assets/deleteIcon.svg" class="h-5 w-5 " />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="Popup min-h-130" v-if="linkpopup">
			<div  class="fixed inset-0 z-50 justify-center items-center flex min-h-130">
				<div class="relative w-3/6 my-6 mx-auto max-w-4xl">
					<div class="popup border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<div class="flex p-4 border-b border-solid border-lightgrey rounded-t">
							<div class="formcontainer flex mr-2">
								<h1>Link Configuration -- {{singleWorkFlow.workFlowName}}</h1>
							</div>
							<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="linkpopup = !linkpopup">
								<!-- <XIcon class="h-5 w-5" /> -->
								<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
							</button>
						</div>
						<div>
							<div class="p-3 w-3/6">
								<label class="controllabel font-normal text-sm mb-2">Select Configuration</label>
								<select class="inputboxstyling" v-model="singleWorkFlow.linked">
									<option value="0" selected hidden disabled>Please select configuration</option>
									<option v-for="config in configList" :value="config._id" :key="config._id">
										{{ config.name}}
									</option>
								</select>
							</div>
						</div>
						<div class="buttonposition flex justify-end items-right mb-4 mx-2">
							<button :class="singleWorkFlow.linked == presentConfiguration?'btndisabled pointer-events-none':'btnprimary'" class="mr-1.5" @click="cancelConfiguration()">Cancel</button>
							<button :class="singleWorkFlow.linked == presentConfiguration?'btndisabled pointer-events-none':'btnprimary'" class="mr-5" @click="updateWorkFlow(singleWorkFlow)">Save</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="linkpopup" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</div>
	</div>
</template>
<style scoped>
.mytable {
	box-shadow: none;
}
td:last-child {
	text-align: center;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import moment, { now } from 'moment';
import { toast } from '../main';
import { emitter } from '../main';
export default defineComponent({
	data(): any {
		return {
			allWorkFlows: [],
			workflowPopup: false,
			linkpopup:false,
			optionsUserArray:[],
			singleWorkFlow: {},
			showFilters: false,
			filterDummy:{},
			classSort: '',
            className: '',
            pageType: '',
            pop: false,
            xPos: '',
            yPos: '',
            selected: '',
            pageSize: 20,
            currentPage: 1,
            currentSort: 'name',
            currentSortDir: 'asc',
			dummyWorkFlows:[],
			configList:[],
			presentConfiguration:'',
			dummySingleWorkflow:{},
		};
	},
	computed: {
        sortedData: function (): any {
            return [...this.allWorkFlows]
                .filter((row:any) => {
                    for (const key in this.filterDummy) {
                        const inputValue = this.filterDummy[key];
                        if (inputValue !== '' && row[key]) {
							// console.log("akwubd",row[key])
							if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
								return false;
							}	
                        }                    
                    }
                    return true;
                })
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
	methods: {
		sort: function (s: any) {
            this.className = s;
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = s;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
		async getWorkflows() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master`).then((res: any) => {
				this.allWorkFlows = res.data;
				this.allWorkFlows.forEach((singleworkflow: any) => {
					let now = moment(singleworkflow.createdAt);
					singleworkflow.createdAt = now.format('DD-MM-YYYY');
				});
			});
			console.log('workflows', this.allWorkFlows);
		},
		cancelConfiguration(){
			this.singleWorkFlow = this.dummySingleWorkflow
		},
		async updateWorkFlow(workflow: any) {
			let now = moment();
			let a = workflow._id;
			workflow.updatedAt = now.format();
			delete workflow._id;
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${a}/update`, workflow)
				.then((res: any) => {
					this.insertedId = res.data;
					console.log('this.insertedId', this.insertedId);
					if (res.status == 200) {
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.linkpopup=false
			// this.$router.push({ name: 'editWorkflow', params: { workflowId: this.insertedId } });
		},

		getAuthorityName(id:any){
			console.log("iddddd",id)
			console.log("optionss",this.optionsUserArray)
			console.log("someeee",this.optionsUserArray.filter((obj:any) => obj.value === id))
			return this.optionsUserArray.filter((obj:any) => obj.value === id)[0].label

			// if(this.optionsUserArray>0){
			// 	for(var i=0;i<this.optionsUserArray.length;i++){
			// 	if(	this.optionsUserArray[i].value==id){
			// 		return this.optionsUserArray[i].name
			// 	}

			// 	}
			// }
		},
		async deleteWorkFlow(workflow: any) {
			await this.$http
				.delete(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${workflow._id}/delete`)
				.then((response: any) => {
					toast.info('Workflow Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.getWorkflows();
				})
				.catch((error: any) => {
					toast.error('Workflow Not Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			console.log('config list', this.allWorkFlows);
		},
		editStatus(id: any) {
			this.workflowPopup = false;
			this.singleWorkFlow = this.allWorkFlows.find((workflow: any) => {
				return workflow._id == id;
			});
			this.$router.push({ name: 'editWorkflow', params: { workflowId: this.singleWorkFlow._id } });
		},
		tablepopup(id: any) {
			this.workflowPopup = true;
			this.singleWorkFlow = this.allWorkFlows.find((workflow: any) => {
				return workflow._id == id;
			});
			emitter.emit("singleWorkFlowEmit",this.singleWorkFlow)
		},
		filterOpen() {
            this.showFilters = !this.showFilters;
             this.filterDummy={}
            if (this.showFilters == true) {
                this.dummyWorkFlows = this.allWorkFlows;
            } else {
                this.allWorkFlows = this.dummyWorkFlows;
            }
        },
		async getallConfigurations() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master`).then((res: any) => {
				console.log('config list', res.data);
				this.configList = res.data;
			});
		},
		linkPopup(id: any) {
			this.linkpopup = true;
			this.singleWorkFlow = this.allWorkFlows.find((workflow: any) => {
				return workflow._id == id;
			});
			this.presentConfiguration = this.singleWorkFlow.linked;
			this.dummySingleWorkflow = {...this.singleWorkFlow};
		},
		addWorkflow() {
			this.$router.push({ name: 'createWorkflow' });
		},
			async getallTeamMembers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
				this.orgUsersList=this.orgUsersList.filter((user:any) => {
					return user.isActive==true
				});
				console.log("this.orgUsersList",this.orgUsersList)
				for (var i = 0; i < this.orgUsersList.length; i++) {
					this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
				}
			});
			console.log("options",this.optionsUserArray)
		},
	
	},
	async mounted() {
		await this.getWorkflows();
		await this.getallConfigurations();
		await this.getallTeamMembers()
	}
});
</script>