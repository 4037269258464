<template>
	<div class="rootContainer" v-if="!loading">
		<component v-bind:is="layout">
	 <router-view :key="$route.path" />
		</component>
	</div>
	<div v-else>
		<spinner />
	</div>
</template>
<style  scoped>
.rootContainer {
	background-color: #F9F9FB;
	min-height: 100vh;
}
</style>
<script lang="ts">
import emptylayout from "@/layouts/emptyLayout.vue";
import { defineComponent } from "vue";
export default defineComponent({
  computed: {
    layout() {
      return this.$route.meta.layout || emptylayout;
    },
  },
});
</script>