<template>
	<div class="pt-5 px-4">
		<div class="flex justify-between">
			<div>
				<h1>Workflow</h1>
			</div>
		</div>
		<div v-if="onlyDataShow || clickonRow">
			<div class="card justify-center items-center flex mt-4">
				<div>
					<div class="flex flex-col w-full bg-white">
						<!--header-->
						<!-- <div class="flex p-5 border-b border-solid border-lightgrey rounded-t">
							<div class="formcontainer flex mr-2">
								<h1>Create Workflow</h1>
							</div>
						</div> -->
						<div class="statuscontainer p-2">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full">
									<!-- <div class="col-md-6 w-6/12 p-2">
										<label class="controllabel">WorkFlow:</label><br />

										<div class="ml-4">{{ workflows[0].workFlowName }}</div>
									</div> -->
									<div class="w-1/2 p-2">
										<label for="workflowName" class="controllabel w-1/2">WorkFlow*</label>
										<input id="workflowName" type="text" class="inputboxstyling" placeholder="Name of the workflow" disabled :value="workflows[0].workFlowName"  />
									</div>
											
									<div class="w-1/2 p-2">
										<label for="configuration" class="controllabel w-1/2">Link Configuration</label>
										<!-- <input type="text" id="configuration" class="inputboxstyling" placeholder="Name of the configuration"/> -->
										<select class="inputboxstyling" disabled v-model="workflows[0].linked">
											<option value="0" selected hidden disabled>Please select screen</option>
											<option v-for="config in configList" :value="config._id" :key="config._id">
												{{ config.name }}
											</option>
										</select>
									</div>
									<div class="p-2 mt-5 flex items-center flex-row-reverse">
										<button class="btnprimary h-8 flex justify-center items-center" @click="editStatus()">
											<img src="@/assets/editIcon.svg" class="h-5 w-5 mr-1" />
											Edit
										</button>
									</div>
								</div>
							</div>
							<hr class="mb-4" />
						</div>
						<table>
							<thead>
								<tr>
									<th class="cursor-pointer"><div>Status</div></th>
									<th class="cursor-pointer"><div>Stage</div></th>
									<!-- <th class="cursor-pointer">From</th> -->
									<th class="cursor-pointer"><div>To</div></th>
									<th class="cursor-pointer"><div>Authority</div></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="status in workflows[0].statuses" :key="status">
									<!-- {{status.label}}--{{workflows[0].statuses[statusIndex].to.label}} -->
									<!-- <div > -->
										 <!-- <td>
											{{ status.label }}
										</td> -->
									<td>
										{{ status.label }}
										<div v-for="stage in status.stages" :key="stage._id" class="change">
											<div style="visibility: hidden">
												{{ status.label }}
											</div>
										</div>
									</td>
									<td>
										<div style="visibility: hidden">
											{{ status.label }}
										</div>
										<div v-for="stage in status.stages" :key="stage._id" class="change mt-3">
											{{ stage.label }}
										</div>
									</td>
									<!-- <td>
										<div v-if="status.label == 'OPEN'">
											<div >
												{{ status.from.label }}
											</div>
										</div>
										<div v-else>
											{{ status.from.label }}
										</div>
										<div v-if="status.label == 'OPEN'">
											<div>
												<div v-for="stage in status.stages" :key="stage._id" class="change">
													<div style="visibility: hidden">
														{{ stage.to.label }}--{{1}}
													</div>
												</div>
											</div>
										</div>
										<div v-else>
											<div v-for="stage in status.stages" :key="stage._id" class="change">
												{{ stage.to.label }}=={{1}}
											</div>
										</div>
									</td> -->
									<td>
										<div v-if="status.label == 'CLOSED'">
											
											<div>
												{{ status.label }}
											</div>
										</div>
										<div v-else>
											<div>
												{{ status.toLabel }}
											</div>
										</div>
										<div v-if="status.label == 'CLOSED'">
											<div>
												<div v-for="stage in status.stages" :key="stage._id" class="change">
													<div style="visibility: hidden">
														{{ 1111 }}
													</div>
												</div>
											</div>
										</div>
										<div v-else>
											<div v-for="stage in status.stages" :key="stage._id" class="change mt-4">
												<div>
													{{ stage.toLabel }}
												</div>
											</div>
										</div>
									</td>
									<td>
										{{ (optionsUserArray.find((obj)=>obj.value == status.authority)).label }}
										<!-- <div v-for="stage in status.stages" :key="stage._id" class="change">
											{{ stage.authority[0] }}
										</div> -->
									</td>
									<!-- </div> -->
								</tr>
							</tbody>
						</table>

						<div class="buttonposition flex justify-end items-right my-3 mx-2">
							<button class="btnprimary mr-1.5" @click="closeWorkflow()">Close</button>
							<!-- <button class="btnprimary" v-if="this.pageType == 'create'">Save</button>
							<button class="btnprimary" @click="updateWorkFlow(workFlow)" v-else>Update</button> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="clickOnEdit">
			<div class="card justify-center items-center flex mt-4">
				<div>
					<!--content-->
					<div class="flex flex-col w-full bg-white">
						<!--header-->
						<div class="flex p-2 border-b border-solid border-lightgrey rounded-t">
							<div class="formcontainer mr-2 row flex flex-row w-full">
								<div class="col-md-6 w-6/12 p-2">
									<h1>Create Workflow</h1>
								</div>
								<!-- <div class="col-md-6 w-6/12 p-2 flex flex-row-reverse">
									<img src="@/assets/link.svg" class="h-5 w-6 cursor-pointer" @click="linkConfigurations()" />
								</div> -->
							</div>
						</div>
						<div v-for="(workFlow, workFlowindex) in workflows" :key="workFlow._id">
							<!-- {{v$.workflows[workFlowindex].statuses}} -->
							<div>
								<div class="statuscontainer px-2 pt-2">
									<div class="formcontainer row flex items-center">
										
										<div class="w-1/2 p-2">
											<label for="workflowName" class="controllabel w-1/2">WorkFlow*</label>
											<input id="workflowName" type="text" class="inputboxstyling" placeholder="Name of the workflow" v-model="workFlow.workFlowName" :class="{
															'is-invalid':v$.workflows[0].workFlowName.$error }" />
											<div v-if="v$.workflows[0].workFlowName.$error" class="text-red-500">		
												<div class="error-text" v-if="v$.workflows[0].workFlowName.required.$invalid">Required</div>
											</div>
										</div>
										
										<div class="w-1/2 p-2">
											<label for="configuration" class="controllabel w-1/2">Link Configuration</label>
											<!-- <input type="text" id="configuration" class="inputboxstyling" placeholder="Name of the configuration"/> -->
											<select class="inputboxstyling" v-model="workFlow.linked">
												<option value="0" selected hidden disabled>Please select screen</option>
												<option v-for="config in configList" :value="config._id" :key="config._id">
													{{ config.name }}
												</option>
											</select>
										</div>

										<div class="flex flex-row-reverse px-2 pt-5" id="container">
											<button class="btnprimary relative flex justify-center items-center" @click="showBlock=!showBlock">Add <img src="@/assets/Collapse.svg" alt="" class="ml-2"></button>
											<!-- <ul class="card shadow-sm absolute addButton cursor-pointer" v-if="showBlock" >
												<li @click="addStatus(workFlow, 'Need for Approval')" class="px-2 py-2">Need for Approval</li>
												<hr class="border w-full">
												<li @click="addStatus(workFlow, 'status')" class="px-2 py-2">Status</li>
											</ul> -->
											<ul class="addCard bg-white absolute cursor-pointer" v-if="showBlock">
												<li @click="addStatus(workFlow, 'Need for Approval')" class="px-3 py-3">Need for Approval</li>
												<hr class="border w-full">
												<li @click="addStatus(workFlow, 'status')" class="px-3 py-3">Status</li>
											</ul>
										</div>
										
									</div>
									<hr class="mb-4 mt-4" />
								</div>
							</div>
							<div class="p-2 mt-8">
								<table>
									<tr>
										<th>Status</th>
										<th>Stage</th>
										<!-- <th>From</th> -->
										<th>To</th>
										<th>Authority</th>
										<th class="text-center">Action</th>
									</tr>
									<tr v-for="(status, statusIndex) in workFlow.statuses" :key="statusIndex">
											<!-- <div class="mt-8 overflow-scroll">{{v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex]}}</div> -->
										
										<td>
											<div v-if="status.label == 'Open' || status.label == 'Closed' || status.label == 'Need for Approval' ">
												{{ status.label }}
												<div v-for="stage in status.stages" :key="stage.id" class="mt-1 change" style="visibility: hidden">
													<input type="text" class="inputboxstyling" v-model="status.label" @blur="addStatusAddress(status.label)" />
												</div>
											</div>
											
										
											<div v-else class="mt-2">
												<input type="text" class="inputboxstyling" v-model="status.label" @blur="addStatusAddress(status.label)" 
												
												:class="{'is-invalid': v$.workflows[workFlowindex].$error && !v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].label.required }" />
												<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
													<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].label.required">Required</div>
												</div>
												<div v-for="stage in status.stages" :key="stage.id" class="mt-1 change" style="visibility: hidden">
													<input type="text" class="inputboxstyling" v-model="stage.label" @blur="addStatusAddress(stage.label)" />
														
														<!-- <div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
												<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].label.required">Required</div>
												</div> -->
												</div>
											</div>
										</td>
										<td>
											<div>
												<div class="mt-2" style="visibility: hidden">
													<input type="text" class="inputboxstyling" />
												</div>
												<div v-for="(stage,stageIndex) in status.stages" :key="stage.id" class="mt-1 change">
													<div class="mt-2">
														
														<input type="text" class="inputboxstyling" v-model="stage.label" @blur="addStageAddress(stage.label)"
														:class="{
																'is-invalid': v$.workflows[workFlowindex].$error && !v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].stages.$each.$data[stageIndex].label.required
																}" />
														<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
															<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].stages.$each.$data[stageIndex].label.required">Required</div>
														</div>
													</div>
												</div>
											</div>
											
										</td>
											<!-- <td v-if="status.label == 'OPEN'"></td>	
											<td v-else>
											<div class="mt-2" >
												<Multiselect v-model="status.from.label" :groups="true" :options="optionsData" />
											</div>
											<div v-for="stage in status.stages" :key="stage.id" class="mt-1 change">
												<div class="mt-2">
													<Multiselect :groups="true" :options="optionsData" v-model="stage.from.label" />
												</div>
											</div>
										</td> -->
										<!-- <td v-if="workFlow.statuses.length - 1 == statusIndex"></td> -->
										<td >
											
											<div class="mt-2">
																								<!-- {{v$.workflows[workFlowindex].$error}}
												{{v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex]}}  -->
												<!-- {{v$.workflows[workFlowindex].$error}}
												
												{{v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].to.label.required}}  -->
												<!-- <Multiselect v-model="status.toLabel" :groups="true" :options="optionsData" 
												:class="{ 'is-invalid':!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].toLabel.required }" /> -->
												<select class="inputboxstyling jaga" v-model="status.toLabel" 
												:class="{ 'is-invalid':v$.workflows[workFlowindex].$error && !v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].toLabel.required }">
														 <optgroup v-for="obj in optionsData" :key="obj" :label="obj.label">
															<option v-for="option in obj.options" :value="option" :key="option">{{option}}</option>
														</optgroup>
												</select>
												<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
													<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].toLabel.required">Required</div>
												</div>
												<!-- <div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
												<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex]">Required</div>
												</div> -->
												<!-- {{v$}} -->
												<!-- <div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
												<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].to.label.required">Required</div>
												</div> -->
												<!-- {{v$.workflows[0].statuses[0].to.label.required.$invalid}} -->
												<!-- {{v$.statuses.$error}}		<div v-if="v$.statuses.$error">
													<div v-if="!v$.statuses.$each.$response.$data[statusIndex].to.label.required" class="text-red-500">Required</div>
													</div> -->
													<!-- <div v-if="v$.workflows[0].statuses[0].to.label.$error" class="text-red-500">		
													<div class="error-text" v-if="v$.workflows[0].statuses[0].to.label.required.$invalid">Required</div> -->
												<!-- </div> -->
											</div>
											<div v-for="(stage,stageIndex) in status.stages" :key="stage.id" class="mt-1 change">
												<div class="mt-2">
													<!-- <Multiselect :groups="true" :options="optionsData" v-model="stage.toLabel" /> -->
													<select class="inputboxstyling" v-model="stage.toLabel" 
													:class="{'is-invalid':v$.workflows[workFlowindex].$error && !v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].stages.$each.$data[stageIndex].toLabel.required}">
														 <optgroup v-for="obj in optionsData" :key="obj" :label="obj.label">
															<option v-for="option in obj.options" :value="option" :key="option">{{option}}</option>
														</optgroup>
													</select>
													
													<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
														<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].stages.$each.$data[stageIndex].toLabel.required">Required</div>
													</div>
												</div>
											</div>
										</td>
										<!-- <td v-if="workFlow.statuses.length - 1 == statusIndex"></td> -->
											<!-- v-if="status.label == 'Closed' || status.label == 'Need for Approval'" -->
										<td>
											<div class="mt-2 w-52" >
												<!-- {{v$.workflows[0].statuses[0].authority.required.$invalid}}
												{{v$.workflows[0].statuses[0].authority.$error}} -->
												
												<!-- <Multiselect
													v-model="status.authority" 
													:options="optionsUserArray"
												/> -->
													<select class="inputboxstyling" v-model="status.authority" 
													:class="{'is-invalid':v$.workflows[workFlowindex].$error && !v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].authority.required}">
														<option v-for="option in optionsUserArray" :key="option" :value="option.value">{{option.label}}</option>
													</select>

													<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
														<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].authority.required">Required</div>
													</div>
												</div> 
												<!-- <div class="mt-2 w-52" style="visibility: hidden">
													
													<select class="inputboxstyling" v-model="status.authority">
														<option v-for="option in optionsUserArray" :key="option" :value="option.value">{{option.label}}</option>
													</select>

													<div v-if="v$.workflows[workFlowindex].$error" class="text-red-500">		
														<div class="error-text" v-if="!v$.workflows[workFlowindex].statuses.$each.$response.$data[statusIndex].authority.required">Required</div>
													</div>
												</div>  -->
										</td>
										<!-- <td v-else></td> -->
										<td >
											<div class="flex flex-row child-action-col justify-center">
												<div class="px-1 py-0 cursor-pointer" @click="addStage(workFlowindex, statusIndex)">
													<img src="@/assets/plus.svg" class="h-5 w-5 mr-2 mt-2" />
												</div>

												<div @click="ondelete(workFlowindex, statusIndex)" class="px-1 py-0 cursor-pointer">
													<img src="@/assets/deleteIcon.svg" class="h-5 w-5 mt-2" />
												</div>
											</div>
											<div v-for="(stage, stageIndex) in status.stages" :key="stage.id" class="mt-1 change flex flex-row justify-center">
																			<div class="flex flex-row child-action-col justify-center" >
												<div class="px-1 py-0 cursor-pointer" style="visibility: hidden">
													<img src="@/assets/plus.svg" class="h-5 w-5 mr-2 mt-2" />
												</div>

												<div  @click="ondeletestage(workFlowindex, statusIndex, stageIndex)" class="px-1 py-0 cursor-pointer">
													<img src="@/assets/deleteIcon.svg" class="h-5 w-5 mt-2" />
												</div>
												</div>
											</div>
										</td>
									</tr>
								</table>
							</div>
							
							<!--footer-->
							<div class="buttonposition flex justify-end items-right mt-3 mb-3 mx-2">
								<button class="mr-6" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="cancelWorkflow()">Cancel</button>
								<button @click="saveWorkFlow(workFlow,workFlowindex)" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" v-if="this.pageType == 'create'">Save</button>
								<button class="btnprimary" :class="disableStatus ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="updateWorkFlow(workFlow)" v-else>Update</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="Popup" v-for="workFlow in workflows" :key="workFlow._id">
			<div v-if="linkPopup" class="fixed inset-0 z-50 justify-center items-center flex min-h-130">
				<div class="relative w-4/6 my-6 mx-auto max-w-4xl">
					<div class="popup border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						<div class="flex p-5 border-b border-solid border-blueGray-200 rounded-t">
							<div class="formcontainer flex mr-2">
								<h1>Link Configuration</h1>
							</div>
						</div>
						<div>
							<div class="p-3 w-3/6">
								<label class="controllabel font-normal text-sm mb-1">Select Configuration</label>
								<select class="inputboxstyling" v-model="workFlow.linked">
									<option value="0" selected hidden disabled>Please select screen</option>
									<option v-for="config in configList" :value="config._id" :key="config._id">
										{{ config.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="buttonposition flex justify-end items-right mb-4 mx-2">
							<button class="btnprimary mr-1.5" @click="linkPopup = !linkPopup">Cancel</button>
							<button class="btnprimary" @click="saveConfiguration()">Save</button>
						</div>
					</div>
				</div>
			</div>
			<div v-if="linkPopup" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</div>
	</div>
	
</template>
<style scoped>
.addCard{
	margin-top: 32px;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
}
table {
	box-shadow: none;
	margin-top: 5px;
	overflow-y: scroll;
}

</style>
<script lang="ts">
import { defineComponent } from 'vue';
// import Multiselect from '@vueform/multiselect';
import { toast } from '../main';
import _ from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';
import moment from 'moment';
import { FlagIcon } from '@heroicons/vue/outline';

export default defineComponent({
	data(): any {
		return {
			v$:useVuelidate(),
			allworkflows: [],
			insertedId: '',
			linkPopup: false,
			showBlock:false,
			isSaved: false,
			configList: [],
			singleWorkFlow: [],
			workflows: [
				{
					workFlowName: '',
					isActive: '',
					categories: [
						{
							name: '',
							_id: ''
						}
					],
					statuses: [
						{
							type: 10801,
							label: 'Open',
							fromType:'',
							fromLabel:'',
							toType:'',
							toLabel: '', 
							authority: [],
							stages: []
						},
						{
							type: 10802,
							label: 'Closed',
							fromType:'',
							fromLabel:'',
							toType:'',
							toLabel: '', 
							authority: [],
							stages: []
						}
					],
					linked:0
				}
			],
			emptyObj:{},
			workFlowCopy:null,
			userPopup: false,
			usersPopup: false,
			addData: true,
			onlyDataShow: false,
			baseStyles: {
				fontWeight: '800',
				color: 'red'
			},
			statuses: [],
			data: false,
			x: 0,
			statusDropdown: ['Open', 'Closed'],
			stagesDropdown: [],
			a: {},
			b: {},
			abcd: [],
			optionsUserArray: [],
			clickOnEdit:false,
			clickonRow:false,
		};
	},

	validations(){
		return{
			// workflows:[{
			// 	workFlowName:{required},
			// 		statuses: {
			// 	$each: helpers.forEach({
			// 	to: {
			// 					label: {required}
			// 				},
			//  				// authority: {required},
			// 	})
			// }
				
			// }]
			workflows:[
				{
					workFlowName:{required},
					statuses: {
						$each: helpers.forEach({
							label:{required},
							toLabel:{
								required
							},
							// "to.label":{required},
							// to:{required},
							// to:{required},
							// to:{
								// $each: helpers.forEach({

									// label:{required}
								// })
							// },
							authority: {required},
							stages: {
								$each: helpers.forEach({
								label:{required},
								toLabel:{
									required
								},
								})
							}
							})		
						// 			authority: {required},
						// })
					}
				}
			]
		}

	},
	// components: { Multiselect },
	computed: {
		optionsData(): any {
			let abc = [
				{
					label: 'Statuses',
					options: this.statusDropdown
				},
				{
					label: 'Stages',
					options: this.stagesDropdown
				}
			];
			return abc;
		},
		disableStatus: function (): any {
            let result;
            if (this.pageType == 'edit') {
            	let objCopy = Object.assign({}, this.workflows[0]);
				console.log("objcopychanging",objCopy)
				console.log("workflowcopyassign",this.workFlowCopy)
              if (_.isEqual(this.workFlowCopy, objCopy)) {
					result = false;
                } else {
                    result = true;
                }
            } else if(this.pageType == 'create') {
				console.log("empty",this.emptyObj)
				console.log("work",this.workflows[0])
			   if (_.isEqual(this.emptyObj, this.workflows[0])) {
                    result = false;
                } else {
                    result = true;
                }
            }
            return result;
        },
	},
	methods: {
		//      RefreshParent() {
		//     if (window.opener != null && !window.opener.closed) {
		//         window.opener.location.reload();
		//     }
		// },
		colorWorkflowBg(Status: any) {
            if (Status == "Closed") {
                return ['bg-darkred', 'opacity-10'];
            } else if (Status == "Open") {
                return ['bg-lightgreen', 'opacity-10'];
            } else if (Status == "Need for Approval") {
                return ['bg-yellow', 'opacity-10']
            }
        },
        colorWorkflowText(Status: any) {
             if (Status == "Closed") {
                return ['text-darkred', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-darkred'];
            } else if (Status == "Open") {
                return ['text-lightgreen', 'flex justify-center px-2.5 py-1 font-semibold border-solid border border-electricGreen'];
            } else if (Status == "Need for Approval") {
                 return ['text-yellow', 'px-2.5 py-1 flex justify-center font-semibold border-solid border border-yellow'];
            }
        },
		async getallConfigurations() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master`).then((res: any) => {
				console.log('config list', res.data);
				this.configList = res.data;
			});
		},
		saveConfiguration(): any {
			this.linkPopup = false;
		},
		linkConfigurations() {
			this.linkPopup = true;
		},
		ondeletestage(workflowIndex: any, statusIndex: any, stageIndex: any) {
			this.workflows[workflowIndex].statuses[statusIndex].stages.splice(stageIndex, 1);
		},
		ondelete(workflowIndex: any, statusIndex: any) {
			this.workflows[workflowIndex].statuses.splice(statusIndex, 1);
			// this.config.controls.splice(controlIndex, 1)
		},
		async cancelWorkflow() {
			// this.onlyDataShow = false;
			// this.$router.push({ name: 'workflows' });
			 if (this.pageType == 'edit') {
				console.log("objcopychanging",this.workflows[0])
				console.log("workflowcopyassign",this.workFlowCopy)
				this.workflows[0] = _.cloneDeep(this.workFlowCopy);
				this.v$.$reset();
             
            } else if(this.pageType == 'create') {
				console.log("empty",this.emptyObj)
				console.log("work",this.workflows[0])
				this.workflows[0] = _.cloneDeep(this.emptyObj);
				this.v$.$reset();
            }
		},
		closeWorkflow(){
			this.onlyDataShow = false;
			this.$router.push({ name: 'workflows' });
		},
		async getSingleWorkflow() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${this.$route.params.workflowId}`).then((res: any) => {
				this.singleWorkFlow = res.data;

				this.singleWorkFlow[0].statuses.map((singleObj:any)=>{
					singleObj.fromType = singleObj.from.type;
					singleObj.fromLabel = singleObj.from.label;
					delete singleObj.from;
					singleObj.toType = singleObj.to.type;
					singleObj.toLabel = singleObj.to.label;
					delete singleObj.to;
					if(singleObj.stages.length>0){
						for(let i=0;i<singleObj.stages.length;i++){
							singleObj.stages[i].toType = singleObj.stages[i].to.type;
							singleObj.stages[i].toLabel = singleObj.stages[i].to.label;
							delete singleObj.stages[i].to;
							singleObj.stages[i].fromType = singleObj.stages[i].from.type;
							singleObj.stages[i].fromLabel = singleObj.stages[i].from.label;
							singleObj.stages[i].from;
						}
					}
				})
				this.workflows = this.singleWorkFlow;
			});
		},

		savesWorkFlow() {
			console.log('sssss');
			this.addData = false;
			this.onlyDataShow = true;
			this.$router.push({ name: 'workflows' });
		},
		editStatus(id: any) {
			this.addData = true;
			this.onlyDataShow = false;
			this.clickonRow = false;
			this.clickOnEdit = true;
		},
		addStatusAddress(value: any) {
			this.statusDropdown.push(value);
			this.statusDropdown = [...new Set(this.statusDropdown)];
		},
		addStageAddress(value: any) {
			this.stagesDropdown.push(value);
		},
		async getWorkflows() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master`).then((res: any) => {
				console.log('config list', res.data);
				this.allworkflows = res.data;
			});
		},
		addStatus(singlestatus: any, type: string) {
			const count: any = {};
			console.log(":::")
			singlestatus.statuses.forEach((element:any) => {
			  count[element.label] = (count[element.label] || 0) + 1;
			});
			let a = count['Need for Approval']
			let label = '';
			if (type == 'Need for Approval') {
				
				if(a>=5){
					toast.info('Maximum limit reached', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}else{
					(label = type), 
					this.statusDropdown.push(label);
				}
				this.statusDropdown=[...new Set(this.statusDropdown)]
			} 
			console.log('singlestatus.statuses', singlestatus.statuses);

			let singlestatusindex = singlestatus.statuses.length;
			let fromAddress = singlestatus.statuses[singlestatusindex - 2];
			singlestatus.statuses.splice(singlestatusindex - 1, 0, {
				type: 10803,
				label: label,
				fromType: 10801,
				fromLabel:'',
				toType:'',
				toLabel: '', 
				authority: [],
				stages: []
			});
			this.showBlock = false;
		},
		addStage(workFlowIndex: any, statusIndex: any) {
			let fromAddress: any;
			let a;
			if (this.workflows[workFlowIndex].statuses[statusIndex].stages.length > 0) {
				let a = this.workflows[workFlowIndex].statuses[statusIndex].stages.length - 1;
				fromAddress = this.workflows[workFlowIndex].statuses[statusIndex].stages[a];
			}
			if (fromAddress) {
				a = fromAddress.label;
			} else {
				a = '';
			}
			this.workflows[workFlowIndex].statuses[statusIndex].stages.push({
				label: '',
				authority: [],
				fromType:'',
				fromLabel:a,
				toType:'',
				toLabel: '', 
			});
		},

		async updateWorkFlow(workflow: any) {
			this.v$.$touch();
			this.showBlock = false;
			console.log('ssssnikhil', workflow);
			console.log('ssslogged');
			let errorCondition = this.v$.$silentErrors.length;
			if (errorCondition==0) {
				this.addData = false;
				this.onlyDataShow = true;
				this.clickonRow = true;
				this.clickOnEdit = false;
				workflow.statusDropdown = this.statusDropdown;
				workflow.stagesDropdown = this.stagesDropdown;

				for(let i=0;i<workflow.statuses.length;i++){
					console.log(workflow.statuses[i])
					let to = {
						type:workflow.statuses[i].toType,
						label:workflow.statuses[i].toLabel
					}
					let from = {
						type:workflow.statuses[i].fromType,
						label:workflow.statuses[i].fromLabel
					}
					if(workflow.statuses[i].stages.length>0){
						for(let j=0;j<workflow.statuses[i].stages.length;j++){
							let to={
								type:workflow.statuses[i].stages[j].toType,
								label:workflow.statuses[i].stages[j].toLabel
							}
							let from = {
								type:workflow.statuses[i].stages[j].fromType,
								label:workflow.statuses[i].stages[j].fromLabel
							}

							workflow.statuses[i].stages[j].from = from
							workflow.statuses[i].stages[j].to = to
							
							delete workflow.statuses[i].stages[j].toType
							delete workflow.statuses[i].stages[j].toLabel
							delete workflow.statuses[i].stages[j].fromType
							delete workflow.statuses[i].stages[j].fromLabel
						}
					}

				workflow.statuses[i].to=to;
				workflow.statuses[i].from = from
				delete workflow.statuses[i].toType
				delete workflow.statuses[i].toLabel
				delete workflow.statuses[i].fromType
				delete workflow.statuses[i].fromLabel
			}
			let now = moment();
			let a = workflow._id;
			workflow.updatedAt = now.format();
			delete workflow._id;
			workflow.actions = [{"actionType": 10301,"actionEntityType": 10102,"actionCategory": 20201}];
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/${a}/update`, workflow)
				.then((res: any) => {
					this.insertedId = res.data;
					console.log('this.insertedId', this.insertedId);
					if (res.status == 200) {
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			}
			await this.getSingleWorkflow()
			// this.$router.push({ name: 'editWorkflow', params: { workflowId: this.insertedId } });
			
		},
		async saveWorkFlow(workflow: any,index:any) {
				this.v$.$touch();
				this.isSaved = true;
				this.showBlock = false;
				console.log("dsddd",this.v$.$silentErrors)
				let errorCondition = this.v$.$silentErrors.length;
				if (errorCondition==0) {
				console.log('ssslogged', workflow);

			for(let i=0;i<workflow.statuses.length;i++){
				console.log(workflow.statuses[i])
				let to = {
					type:workflow.statuses[i].toType,
					label:workflow.statuses[i].toLabel
				}
				let from = {
					type:workflow.statuses[i].fromType,
					label:workflow.statuses[i].fromLabel
				}
				if(workflow.statuses[i].stages.length>0){
					for(let j=0;j<workflow.statuses[i].stages.length;j++){
						let to = {
							type:workflow.statuses[i].stages[j].toType,
							label:workflow.statuses[i].stages[j].toLabel
						}
						let from = {
							type:workflow.statuses[i].stages[j].fromType,
							label:workflow.statuses[i].stages[j].fromLabel
						}

						workflow.statuses[i].stages[j].from = from
						workflow.statuses[i].stages[j].to = to
						delete workflow.statuses[i].stages[j].toType
						delete workflow.statuses[i].stages[j].toLabel
						delete workflow.statuses[i].stages[j].fromType
						delete workflow.statuses[i].stages[j].fromLabel
					}
				}
				
				workflow.statuses[i].from = from
				workflow.statuses[i].to = to
				
				delete workflow.statuses[i].toType
				delete workflow.statuses[i].toLabel
				delete workflow.statuses[i].fromType
				delete workflow.statuses[i].fromLabel
			}
			// this.addData = false;
			// this.onlyDataShow = true;
			workflow.statusDropdown = this.statusDropdown;
			workflow.stagesDropdown = this.stagesDropdown;
			let now = moment();
			workflow.createdAt = now.format();
			workflow.actions = [{"actionType": 10301,"actionEntityType": 10102,"actionCategory": 20201}];
			console.log("Obj to be addd",workflow)
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/master/create`, workflow)
				.then((res: any) => {
					this.insertedId = res.data.insertedId;
					console.log('this.insertedId', this.insertedId);
					if (res.status == 200) {
						toast.info('Saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
					
				})
				.catch((error: any) => {
					toast.error('Not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			
				this.$router.push({ name: 'editWorkflow', params: { workflowId: this.insertedId } });
				// await this.getSingleWorkflow()
			}
		},
		async getallTeamMembers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			this.orgUsersList=this.orgUsersList.filter((user:any) => {
					return user.isActive==true
				});
				for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
			});
			console.log("this.orgUsersList",this.orgUsersList)
		},
	},
	async mounted() {
		this.getallTeamMembers()
		this.getallConfigurations();
		this.pageType = this.$route.fullPath.includes('createWorkflow') ? 'create' : 'edit';
		this.data = true;
		if (this.pageType == 'create') {
			this.statusDropdown = ['Open', 'Closed'];
			this.onlyDataShow = false;
			this.clickOnEdit = true;
			this.emptyObj = _.cloneDeep(this.workflows[0]);
		}
		if (this.pageType == 'edit') {
			this.onlyDataShow = true;
			this.clickOnEdit = false;
			await this.getSingleWorkflow();
			console.log('edit', this.singleWorkFlow);
			this.statusDropdown = this.singleWorkFlow[0].statusDropdown;
			this.stagesDropdown = this.singleWorkFlow[0].stagesDropdown;
			
			
			// for(let i=0;i<this.workflows[0].statuses.length;i++){
				
			// 	this.workflows.statuses[i].toType = this.workflows.statuses[i].to.type
			// 	this.workflows[0].statuses[i].toLabel = this.workflows[0].statuses[i].to.label
			// 	this.workflows[0].statuses[i].authority.value = this.workflows[0].statuses[i].authority
			// 	delete this.workflows[0].statuses[i].to

				// if(this.workflows[0].statuses[i].stages.length>0){
				// 	for(let j=0;j<this.workflows[0].statuses[i].stages.length;j++){
				// 		let to = {
				// 			type: this.workflows[0].statuses[i].stages[j].toType,
				// 			label: this.workflows[0].statuses[i].stages[j].toLabel
				// 		}
				// 		this.workflows.statuses[i].stages[j].toType = this.workflows.statuses[i].stages[j].to.type
				// 		this.workflows[0].statuses[i].stages[j].toLabel = this.workflows[0].statuses[i].stages[j].to.label
				// 	}
				// }
			// }
			console.log('this.statusDropdown', this.statusDropdown);
			console.log('console.log', this.workflows);
			this.workFlowCopy = _.cloneDeep(this.workflows[0]);
		}
	},
});
</script>