<template>
	<div class="maincontainer py-8 px-4 w-full">
		<div class="flex justify-between">
			<div class="title flex items-center">
				<h1>Configurations</h1>
			</div>
		</div>
		<div>
			<div class="card justify-center items-center flex mt-4" v-if="addData">
				<div class="w-full">
					<!--content-->
					<div class="flex flex-col w-full bg-white">
						<!--header-->
						<div class="p-5 border-b border-solid border-lightgrey rounded-t row flex w-full justify-between items-center">
							<div v-if="config._id" class="formcontainer flex mr-2">
								<h1>Update Configuration</h1>
							</div>
							<div v-else class="formcontainer flex mr-2">
								<h1>Create Configuration</h1>
							</div>
							<div>
								<button class="btnprimary" @click="addControlsToConfig()">Add Fields</button>
							</div>
						</div>
						<div class="statuscontainer p-2">
							<div class="formcontainer flex flex-col">
								<div class="row flex flex-row w-full">
									<div class="col-md-6 w-6/12 p-2">
										<label class="controllabel">Configuration</label>
										<input type="text" class="inputboxstyling" placeholder="Configuration" v-model="config.name" />
										<!-- <div v-if="v$.config.name.$error" class="text-red-500">
											<div class="error-text" v-if="v$.config.name.required.$invalid">Required</div>
										</div> -->
									</div>
								</div>
							</div>
						</div>
						<div class="text-black no-underline mt-0.5 cursor-pointer p-2" v-for="(control, controlIndex) in config.controls" :key="controlIndex">
							<div class="flex bg-white py-2.5 px-2.5 text-lg rounded justify-between accordion-head">
								<div class="title font-opensans text-lg cursor-pointer ml-1 flex" v-if="control.controlType">
									<label :for="control.controlType">{{ control.label }}</label>
									<div v-if="control.controlType == 'TextBox'">
										<input type="text">
									</div>
									<div v-if="control.controlType == 'TextArea'">
										<textarea name="Empty Field"></textarea>
									</div>
									<div v-if="control.controlType == 'Radio'">
										<input type="radio" >
									</div>
									<div v-if="control.controlType == 'CheckBox'">
										<input type="checkbox">
									</div>
									<div v-if="control.controlType == 'Dropdown'">
										<select>
											<option value="">qqqq</option>
											<option value="">ww</option>
											<option value="">ee</option>
										</select>
									</div>
								</div>
								<!-- <div class="flex flex-row align-center">
									<div class="px-1 py-0 cursor-pointer" @click="editConfiguration(controlIndex)">
										<img src="@/assets/editIcon.svg" class="h-5 w-5 mt-2" />
									</div>
									<div @click="deleteConfiguration(config)" class="cursor-pointer">
										<img src="@/assets/deleteIcon.svg" class="h-5 w-5 mt-2" @click="ondelete(controlIndex)" />
									</div>
								</div> -->
							</div>
						</div>
						<hr class="my-2" v-if="config.controls.length>0" />
						<!-- <div  v-for="(control, controlIndex) in config.controls" :key="controlIndex">
									<p>{{ control.label }} ({{ control.controlType }})</p>
								</div> -->
						<div class="userPopup">
							<div v-if="showUserModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-index justify-end items-start flex">
								<div class="relative w-full max-w-xl">
									<!--content-->
									<div class="border-0 rounded relative flex flex-col w-full bg-white h-screen">
										<!--header-->
										<div class="flex items-start justify-between p-3 rounded">
											<h1>User Details</h1>
											<button class="p-1 ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none" @click="showUserModal = false">
												<!-- <XIcon class="h-5 w-5" /> -->
												<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
											</button>
										</div>
										<!-- <div class="border-b border-solid border-ightgrey"></div> -->
										<div class="p-2">
											<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer">
												<div>
													<hr class="border-t-2" />
													<div class="p-2">
														<div class="controlSelection">
															<div class="w-1/2">
																<label class="controllabel font-normal text-sm mb-1">Please select control type:</label>
																<select
																	class="inputboxstyling"
																	v-model="control.controlType"
																	@change="changeTarget(control)"
																	:class="{
																		'is-invalid': v$.control.controlType.$error
																	}"
																>	
																	<option value="" selected disabled hidden>Select</option>
																	<option>Text-Box</option>
																	<option>Text-Area</option>
																	<option>Radio</option>
																	<option>CheckBox</option>
																	<option>Dropdown</option>
																</select>
																<!-- {{v$}} -->
																<div v-if="v$.control.controlType.$error" class="text-red-500">
																	<div class="error-text" v-if="v$.control.controlType.required.$invalid">Required</div>
																</div>
															</div>
														</div>
														<div class="controlMetaData flex mt-5">
															<div v-if="control.controlType" class="w-3/5">
																<label class="controllabel font-normal text-sm mb-1">Label for control</label>
																<input
																	type="text"
																	class="inputboxstyling"
																	v-model="control.label"
																	:class="{
																		'is-invalid': v$.control.label.$error
																	}"
																/>
																<div v-if="v$.control.label.$error" class="text-red-500">
																	<div class="error-text" v-if="v$.control.label.required.$invalid">Required</div>
																</div>
															</div>
															<div class="flex flex-col justify-center items-center w-1/5 ml-3" v-if="control.controlType">
																<label class="controllabel font-normal text-sm">Mandatory</label>
																<label class="switch">
																	<input type="checkbox" v-model="control.mandatory" />
																	<span class="slider round"></span>
																</label>
															</div>
															<!-- <div class="flex flex-col justify-center items-center w-1/5 ml-3" v-if="control.controlType">
																<label class="controllabel font-normal text-sm">Delete</label>
																<div @click="onControlDelete(control)">
																	<img src="@/assets/deleteIcon.svg" class="h-7 w-7" />
																</div>
															</div> -->
														</div>
														<div class="controlOptions flex mt-5">
															<div v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox' || control.controlType == 'Dropdown'" class="w-1/2">
																<label class="controllabel font-normal text-sm mb-1">Option(s)</label>
																<input
																	type="text"
																	class="inputboxstyling"
																	v-model="control.option"
																/>
															</div>
														</div>
														<div>
															<div class="optionscontainer border rounded-sm border-lightgrey mt-2.5 p-1.5 text-black flex justify-between bg-darkslategrey min-h-30" v-for="(option, optionIndex) in control.options" :key="option">
																<div class="imgoptioncontainer">
																	<div class="ml-2">
																		{{ option.option }}
																	</div>
																</div>
																<div class="flex">
																	<div @click="onOptionEdit(optionIndex)">
																		<img src="@/assets/editIcon.svg" class="h-5 w-5" />
																	</div>
																	<div @click="onOptionDelete(optionIndex)">
																		<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
																	</div>
																	<div @click="onOptionUpdate(optionIndex)" v-if="hideUpdateIcon">
																		<img src="@/assets/correctIcon.svg" class="w-5 h-5" />
																	</div>
																</div>
																<!-- <div class="gap-1.5 mt-6 ml-3" v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox' || control.controlType == 'Dropdown'"  @click="onOptionUpdate( optionIndex)">
																<button class="btnprimary">update</button>
															</div> -->
															</div>
															<div class="gap-1.5 mt-6 ml-3" v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox' || control.controlType == 'Dropdown'">
																<button :class="(control.option == '' || control.option == undefined)?'btndisabled pointer-events-none':'btnprimary'" @click="addLabelsforInputControls(control.option,controlIndex)">Add</button>
															</div>
														</div>
													</div>
													<div class="flex justify-center">
														<button class="mb-4" :class="control.controlType == ''?'btndisabled pointer-events-none':'btnprimary'" @click="saveControl(control)" v-if="!updateButton">Save</button>
														<button class="btnprimary mb-4" @click="updateControl(controlIndex)" v-if="updateButton">Update</button>
													</div>
													<!-- <div class="flex justify-center">
														<button class="btnprimary mb-4" @click="ondelete(controlIndex)">Delete</button>
													</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="showUserModal" class="opacity-40 fixed inset-0 z-50 bg-black"></div>
						</div>
						<div class="flex justify-end mb-4 items-end mr-4 mt-4" v-if="config.controls.length">
							<div v-if="pageType == 'create'">
								<button :class="defNotEqual ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" class="ml-10 mr-10" @click="save()">Save</button>
							</div>
							<div v-else>
								<button :class="defNotEqual ? 'btnprimary opacity-100' : 'btndisabled pointer-events-none'" @click="update()">update</button>
							</div>
						</div>
						<!-- <div class="flex justify-end mb-4 items-end mr-4 mt-4"> -->
						<!-- v-if="config.controls.length" -->
						<!-- <button class="btnprimary mx-4" @click="save()" >Create</button> -->

						<!-- <button class="btnprimary" @click="update()">Cancel</button>
							<button @click="save()" class="mx-4" :class="pageType == 'create' ? 'btnprimary' : 'btndisabled pointer-events-none'">Create</button> -->
						<!-- </div> -->
					</div>
				</div>
			</div>
			<!-- previewform -->
			<div class="card justify-center items-center flex mt-4" v-if="showPreview">
				<div class="w-full">
					<div class="flex flex-col w-full bg-white">
						<div class="flex p-5 border-b border-solid border-lightgrey rounded-t">
							<div class="formcontainer flex mr-2">
								<h1>Create Configuration</h1>
							</div>
						</div>
						<div>
							<div class="statuscontainer p-2">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full">
										<div class="col-md-6 w-6/12 p-2">
											<label class="controllabel">Configuration:</label>
											{{ config.name }}
										</div>
									</div>
								</div>
								<hr />
							</div>
						</div>
						<div class="p-2">
							<div class="text-black no-underline mt-0.5 min-h-30 cursor-pointer" v-for="(control, controlIndex) in config.controls" :key="controlIndex">
								<div class="accordion-body bg-white">
									<div class="p-2">
										<div class="controlMetaData flex mt-5">
											<div class="w-1/2">
												<label class="controllabel font-normal text-sm mb-1">{{ control.label }}</label>
												<input type="text" class="inputboxstyling" v-if="control.controlType == 'TextBox' || control.controlType == 'TextArea'" />
											</div>
										</div>
										<div v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox'">
											<div class="min-h-30" v-for="option in config.controls[controlIndex].options" :key="option">
												<input type="radio" v-if="control.controlType == 'Radio'" />
												<input type="checkbox" v-if="control.controlType == 'CheckBox'" />
												<label class="ml-2 mb-2"> {{ option.option }}</label>
											</div>
										</div>
										<div v-if="control.controlType == 'Dropdown'">
											<select class="inputboxstyling">
												<option v-for="option in config.controls[controlIndex].options" :key="option">
													{{ option.option }}
												</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="flex justify-end mb-4 items-end mr-4">
								<button class="btnprimary" @click="close()">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.z-index {
	z-index: 999999;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { requiredIf } from '@vuelidate/validators';
import { toast } from '../main';
import moment from 'moment';
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			showPreview: false,
			addData: true,
			updateButton: false,
			defNotEqual: false,
			dummyConfigObj: [],
			remodelObject: [],
			defaultObject: [],
			oldCofigControls: [],
			controlType: '',
			control: {
				controlType: '',
				options: [],
				label: '',
				mandatory: false,
				isShow: false
			},
			config: {
				controls: [],
				toggleConfigInput: false,
				name: ''
			},
			inputControls: [],
			hideUpdateIcon: false,
			showUserModal: false
		};
	},
	validations(smIndex: any) {
		return {
			control: {
				controlType: { required },
				label: { required },
			}
		};
	},
	methods: {
		checkCheckbox() {
			let defaultInfoArr: Array<any> = this.defaultObject;
			console.log('defaultInfoArr', defaultInfoArr);
			let finalInfoArr: Array<any> = this.remodelObject;
			console.log('finalInfoArr', finalInfoArr);
			if (JSON.stringify(defaultInfoArr) !== JSON.stringify(finalInfoArr) || JSON.stringify(this.oldCofigControls) !== JSON.stringify(this.config.controls)) {
				this.defNotEqual = true;
			} else {
				this.defNotEqual = false;
			}
		},
		changeTarget(control: any) {
			console.log('sss', process);
			this.controlType = control.controlType;
		},
		close() {
			this.showPreview = false;
			this.$router.push({ name: 'configurations' });
		},
		ondelete(controlIndex: any) {
			console.log('sss', controlIndex);
			this.config.controls.splice(controlIndex, 1);
			// this.dummyConfigObj = { ...this.config }
			// this.defaultObject.push(this.dummyConfigObj)
			// console.log('defaultObject', this.defaultObject);
		},
		editConfiguration(controlIndex: any) {
			this.updateButton = true;
			this.control = this.config.controls[controlIndex];
			this.showUserModal = true;
			this.dummyConfigObj = { ...this.config };
			this.defaultObject.push(this.dummyConfigObj);
			console.log('defaultObject', this.defaultObject);
		},
		addControlsToConfig(): any {
			this.showUserModal = true;
			this.updateButton = false;
			this.control = {
				controlType: '',
				options: [],
				label: '',
				mandatory: false,
				isShow: false
			};
			console.log('qwertyItems', this.config.controls);
			this.oldCofigControls = { ...this.config.controls };

			// this.config.controls=[
			// 	{
			// 		controlType: '',
			// 		options: [],
			// 		label: '',
			// 		mandatory: false,
			// 		isShow: false
			// 	}
			// ]
		},
		saveControl(control: any) {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				this.v$.$reset();
				this.showUserModal = false;
				// let a=control
				this.config.controls.push(control);
				this.remodelObject.push(this.config);
				console.log('remodelObject', this.remodelObject);
				console.log('qwerttyControl', this.config);
			}
			this.checkCheckbox();
		},
		updateControl(control: any) {
			console.log('sss');
			this.showUserModal = false;
			this.control = control;
			// let a=control
			// this.config.controls.push(this.control)
		},

		addLabelsforInputControls(optionData: any): any {
			this.addData = true;
			this.showPreview = false;
			this.control.options.push({ option: optionData });
			this.control.option = ''
			// delete this.config.controls[index].option;
		},
		// onControlDelete(control:any){
		// 	control.splice(1);
		// },
		onOptionEdit(optionIndex: any): any {
			this.control.options[optionIndex].hideUpdateIcon = true;
			this.control.option = this.control.options[optionIndex].option;
			this.checkCheckbox();
		},
		onOptionDelete(optionIndex: any): any {
			this.control.options.splice(optionIndex, 1);
			this.checkCheckbox();
		},
		onOptionUpdate(optionIndex: any) {
			console.log('sss');
			this.control.options[optionIndex].option = this.control.option;
			this.checkCheckbox();
		},
		async save() {
			this.addData = false;
			this.showPreview = true;
			let now = moment();
			this.config.createdAt = now.format();
			console.log('save', this.config);
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master/create`, this.config)
				.then((res: any) => {
					// this.$router.back();
					if (res.status == 200) {
						toast.info('Saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async update() {
			this.addData = false;
			this.showPreview = true;
			let now = moment();
			this.config.updatedAt = now.format();
			delete this.config._id;
			console.log('save', this.config);
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master/${this.$route.params.configurationId}/update`, this.config)
				.then((res: any) => {
					// this.$router.back();
					if (res.status == 200) {
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async getSingleConfiguration() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master/${this.$route.params.configurationId}`).then((res: any) => {
				// this.config = res.data[0];
				res.data.map((obj: any) => {
					console.log('obj', obj);
					this.config = obj;
					this.dummyConfigObj = { ...obj };
					console.log('aaaasingle', this.config);
					this.defaultObject.push(this.dummyConfigObj);
					console.log('dummyConfigObj', this.dummyConfigObj);
				});
			});
		}
	},
	async mounted() {
		this.pageType = this.$route.fullPath.includes('createconfiguration') ? 'create' : 'edit';
		this.data = true;
		if (this.pageType == 'edit') {
			this.getSingleConfiguration();
		}
	}
});
</script>