<template>
	<div class="maincontainer py-8 px-4">
		<div class="flex justify-between">
			<div>
				<h1>HR Service Management</h1>
			</div>
		</div>
		<div class="flex justify-around flex-wrap" v-if="allProcesses.length>0">
			<div v-for="processes in Processes" :key="processes._id" @click="showConfiguration(processes._id)" class="mt-2">
		<div class="card flex mt-4 cursor-pointer w-80 h-48" >
			<div class="p-2 gap-4 m-4 flex">
				<div class="w-1/5 flex justify-center items-center">
					<img src="@/assets/settings.svg" alt="" class="w-8 h-8" />
				</div>
				<div class="flex flex-col w-4/5">
					<h1>{{ processes.name }}</h1>
					<p class="mt-2 text-sm overflow">
						fact that readable fact that readable fact that readable fact fact that readable fact that readable fact that readable fact that readable fact that readable fact that readable that readable fact that readable fact that readable content of a page when looking at its layout
					</p>
					<div
                  class="absolute top-full left-1/2 z-20 mt-3 size rounded bg-black py-[6px] px-4 text-white opacity-0 group-hover:opacity-100"
                >
                  <span
                    class="absolute top-3 left-1/2 -z-10 h-2 w-2 rotate-45 rounded-sm bg-black"
                  ></span>
                  fact that readable fact that readable fact that readable fact fact that readable fact that readable fact that
	readable fact that readable fact that readable fact that readable that readable fact that readable fact that
	readable content of a page when looking at its
	layout
                </div>
				</div>
			</div>
		</div>
			</div>
		</div>
		<div class="flex justify-center items-center" v-else>
			<p>No Data</p>
		</div>
		<div class="Popup w-full    overflow-y-auto fixed inset-0 z-index  " v-if="showPreview">
			<div class="fixed inset-0 z-index justify-center items-center flex">
				<div class="relative w-4/6 my-6 mx-auto max-w-xl">
					<div class="popup border-0 rounded-lg shadow-lg relative flex flex-col w-full  outline-none focus:outline-none">
						<div class="card justify-center items-center flex mt-4 " v-if="showPreview">
							<div class="w-full bg-red overflow-y-scroll max-h-96">
								<div class="flex flex-col w-full bg-white">
									<div class="flex p-5 border-b border-solid border-lightgrey rounded-t">
										<div class="formcontainer flex mr-2">
											<h1>Create Task</h1>
											<!-- <h1>{{ singleconfig.name }}</h1> -->
										</div>
									</div>
									<div class="p-6">
										<div class="w-full">
											<label class="controllabel font-normal text-sm mb-1">Task Name</label>
											<input type="text" class="inputboxstyling" v-model="tasks.taskName" />
										</div>
										<div class="w-full">
											<label class="controllabel font-normal text-sm mb-1">Summary</label>
											<input type="text" class="inputboxstyling"  v-model="tasks.summary"/>
										</div>
										<div class="w-full">
											<label class="controllabel font-normal text-sm mb-1">Description</label>
											<textarea type="text" class="inputboxstyling" v-model="tasks.description" />
										</div>
										<div class="text-black no-underline  min-h-30 cursor-pointer" v-for="(control, controlIndex) in singleconfig.controls" :key="controlIndex">
												<div >
														<div class="w-full">
															<label class="controllabel font-normal text-sm mb-1">{{ control.label }}</label>
															<input type="text" class="inputboxstyling" v-if="control.controlType == 'TextBox' || control.controlType == 'TextArea'" v-model="control.value" />
														</div>
													<div v-if="control.controlType == 'Radio' || control.controlType == 'CheckBox'">
														<div class="min-h-30" v-for="option in config.controls[controlIndex].options" :key="option">
															<input type="radio" v-if="control.controlType == 'Radio'" />
															<input type="checkbox" v-if="control.controlType == 'CheckBox'" />
															<label class="ml-2 mb-2"> {{ option.option }}</label>
														</div>
													</div>
													<div v-if="control.controlType == 'Dropdown'">
														<select class="inputboxstyling">
															<option v-for="option in config.controls[controlIndex].options" :key="option">
																{{ option.option }}
															</option>
														</select>
													</div>
												</div>
										</div>
										<div class="flex justify-center mt-2  ">
											<button class="btnprimary mr-2" @click="close()">Cancel</button>
											<button class="btnprimary" @click="save(singleconfig.controls)">Save</button>
										</div>
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="showPreview" class="opacity-25 fixed inset-0 z-50 bg-black"></div>
		</div>
	</div>
</template>
<style scoped>
.z-index{
	z-index: 999999;
}
h1 {
	font-size: 19px;
}
.overflow {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}

</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../../main';
import moment from 'moment'

export default defineComponent({
	data(): any {
		return {
			configData: [],
			showPreview: false,
			taskName: '',
			singleconfig: [],
			allProcesses: [],
			allWorkFlows: [],
			tasks:{
				taskName:'',
				description:'',
				summary:''

			}
		};
	},
	created(){
		this.getAllProcesses()

	},
	methods: {
		async save(control: any) {
			let allControls: any = [];
			control.forEach((control: any) => {
				allControls.push({ label: control.label, value: control.value });
			});

			let payload = [
				{
					control: allControls,
					createdAt: moment().format('DD-MM-YYYY'),
					taskName: this.tasks.taskName,
					description: this.tasks.description,
					summary: this.tasks.summary
				}
			];
			this.showPreview = false;
			await this.$http
				.post(`${process.env.VUE_APP_WORKFLOW_API_URL}/task/create`, payload)
				.then((res: any) => {
					if (res.status == 200) {
						toast.info('Saved', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					}
				})
				.catch((error: any) => {
					toast.error('Not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			this.$router.push({ name: 'org-tasks' });
		},
		async getAlltWorkflows() {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/master`).then((res: any) => {
				this.allWorkFlows = res.data;
			});
		},
		
		async showConfiguration(id: any) {
			await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/process/${id}`).then(async (res: any) => {
				let singleProcesses = res.data;
				let workFlow = this.allWorkFlows.filter((workflow: any) => {
					return workflow['_id'] == singleProcesses[0].mappedWorkflow;
				});
				console.log('wwwww', workFlow[0].linked);
				await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/configuration/master/${workFlow[0].linked}`).then((res: any) => {
					console.log('ss', res.data);
					this.singleconfig = res.data[0];
					this.showPreview = true;
				});
			});
		},
		close() {
			this.showPreview =false
		},
		 async getAllProcesses() {
		 await this.$http.get(`${process.env.VUE_APP_WORKFLOW_API_URL}/process`).then((res: any) => {
				this.allProcesses = res.data;
				this.Processes=[]
				
				this.allProcesses.forEach((singleProcesses:any)=>{
					console.log("ssss",singleProcesses.endUsersDepartment._id)

					for(var i=0;i<singleProcesses.endUsersDepartment.length;i++){
						if( singleProcesses.endUsersDepartment[i]._id == this.$route.params.departmentId){
							this.Processes.push(singleProcesses)
						}
					}

					

				})
			});
		}
	},
	mounted() {
		this.getAlltWorkflows();
		this.getAllProcesses();
	}
});


</script>