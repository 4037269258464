<template>
	<div class="fixed h-full bg-primary sidenav flex flex-col z-50 justify-between" :class="{ 'w-40': isSidebarToggle, 'w-12': !isSidebarToggle }">
		<ul>
				<li v-for="(menu, menuIndex) in menus" :key="menuIndex" @click="navigate(menu.optionName, menu.optionRoute)" class="cursor-pointer hover:bg-white">		
				<div :class="selectedOptionName == menu.optionName ? 'bg-whitegrey text-black font-bold' : 'text-lightgrey font-normal'"  class="a"  @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
					<a class="flex navchild" :class="{ 'px-2 py-1': isSidebarToggle, 'px-1.5 py-1': !isSidebarToggle }">
						<img :src="menu.defaultoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3 ': isSidebarToggle, 'w-4': !isSidebarToggle }" v-if="selectedOptionName != menu.optionName && !menu.hover" />
						<img :src="menu.colouredoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3': isSidebarToggle, 'w-4': !isSidebarToggle }" v-else />
						<div class="item-name leading-5 text-sm "  v-if="isSidebarToggle">{{ menu.displayOptionName }}</div>
					</a>
				</div>
				</li>
				<li>
					<div   class="a"  @click="showTeam =!showTeam">
					<a class="flex navchild" :class="{ 'px-2 py-1': isSidebarToggle, 'px-1.5 py-1': !isSidebarToggle }">
						<img src="@/assets/settings.svg" class="h-5 mr-2.5 icon" :class="{ 'w-3 ': isSidebarToggle, 'w-4': !isSidebarToggle }"/>
						<!-- <img :src="menu.colouredoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3': isSidebarToggle, 'w-4': !isSidebarToggle }" v-else /> -->
						<div class="item-name leading-5 text-sm text-white"  v-if="isSidebarToggle">Team</div>
					</a>
				</div>


				<div v-if="showTeam" >
					<ul class="ml-8">
						<li v-for="(team, teamIndex) in teams" :key="teamIndex" @click="navigated(team._id)" class="cursor-pointer hover:bg-white">
				<div :class="selectedOptionName == team.teamName ? 'bg-whitegrey text-black font-bold' : 'text-lightgrey font-normal'"    class="a"  @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
					<a class="flex navchild" :class="{ 'px-2 py-1': isSidebarToggle, 'px-1.5 py-1': !isSidebarToggle }">
						<img :src="team.defaultoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3 ': isSidebarToggle, 'w-4': !isSidebarToggle }" v-if="selectedOptionName != team.teamName" />
						<img :src="team.colouredoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3': isSidebarToggle, 'w-4': !isSidebarToggle }" v-else />
						<div class="item-name leading-5 text-sm"  v-if="isSidebarToggle">{{ team.teamName }}</div>
					</a>
				</div>
			</li> 
					</ul>
				</div>
				</li>
			<!-- <li v-for="(team, teamIndex) in teams" :key="teamIndex" @click="navigated(team._id)" class="cursor-pointer">
				<div :class="selectedOptionName == team.teamName ? 'bg-white' : ''" class="cursor-pointer">
					<a class="flex navchild" :class="{ 'px-2 py-1': isSidebarToggle, 'px-3 py-1': !isSidebarToggle }">
						<img :src="team.defaultoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3 ': isSidebarToggle, 'w-2': !isSidebarToggle }" v-if="selectedOptionName != team.teamName" />
						<img :src="team.colouredoptionImage" class="h-5 mr-2.5 icon" :class="{ 'w-3': isSidebarToggle, 'w-2': !isSidebarToggle }" v-else />
						<div class="item-name leading-5 text-xs" :class="selectedOptionName == team.teamName ? 'text-black font-bold' : 'text-lightgrey font-normal'" v-if="isSidebarToggle">{{ team.teamName }}</div>
					</a>
				</div>
			</li> -->
		</ul>
		<ul class="bottomUl mb-16">
			<li >
				<hr class="mb-4" />
				<img src="@/assets/leftarrow.svg" class="h-8 ml-32 icon leftarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-if="isSidebarToggle" />
				<img src="@/assets/rightarrow.svg" class="h-8 icon ml-2 rightarrow absolute cursor-pointer" alt="" @click="toggleSideMenuBar()" v-else />
			</li>
		</ul>
		<div class="fixed shadow z-50 bg-white w-40 p-4" v-if="appsVisible">
			<div class="flex flex-row items-center cursor-pointer">
				<!-- <div class="flex flex-col mr-1 w-2/4 items-center" @click="triggerNav('monitor')">
					<img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
					<div class="nav-item-label-container">
						<div class="nav-item-label">Monitor</div>
					</div>
				</div> -->
				<div class="flex flex-col w-2/4 items-center cursor-pointer" @click="triggerNav('training')">
					<img src="@/assets/training.svg" alt="" class="icn h-6 w-6 ml-2" />
					<div class="nav-item-label-container">
						<div class="nav-item-label active">Training</div>
						<div class="active-border"></div>
					</div>
				</div>
				<div class="flex flex-col mr-1 w-2/4 ml-2 items-center">
					<img src="@/assets/monitoring.svg" alt="" class="icn h-6 w-6 ml-2" />
					<div class="nav-item-label-container">
						<div class="nav-item-label">Workflow</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped >
.w-3{
	width:15px
}
.shadow {
	--tw-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.leftarrow ,
.rightarrow{
	height: 1.25rem;
	width: 1.25rem;
	margin-top: -27px;
}

.a:hover{
	color:black;
	font-weight: 400;
}

.icon:hover {
transition: none !important;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
// import Multiselect from '@vueform/multiselect';

import { emitter, toast } from '../main';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default defineComponent({
	data(): any {
		return {
			isSidebarToggle: true,
			appsVisible: false,
			hover:false,
			showTeam:false,
			teamsarray:[],
			teams: [
				// {
				// 	_id: '63293b7e2772e22b689a4ff4',
				// 	teamName: 'Dev',
				// 	defaultoptionImage: require('@/assets/settings.svg'),
				// 	colouredoptionImage: require('@/assets/settings.svg'),
				// 	teamOwner: '63368f3016a5fc66006f69e0'
				// },
				// {
				// 	_id: '63368f3016a5fc66006f69de',
				// 	teamName: 'manager',
				// 	defaultoptionImage: require('@/assets/settings.svg'),
				// 	colouredoptionImage: require('@/assets/settings.svg'),
				// 	teamOwner: '63368f3016a5fc66006f69e0'
				// },
				// {
				// 	_id: '63368f3016a5fc66006f69df',
				// 	teamName: 'hr',
				// 	defaultoptionImage: require('@/assets/settings.svg'),
				// 	colouredoptionImage: require('@/assets/settings.svg'),
				// 	teamOwner: '63368f3016a5fc66006f69e1'
				// }
			],
			selectedOptionName: '',
			menus: [
				{
					optionName: 'workflow',
					optionRoute: 'workflows',
					defaultoptionImage: require('@/assets/settings.svg'),
					colouredoptionImage: require('@/assets/settings.svg'),
					displayOptionName: 'Workflow'
				},
				{
					optionName: 'processes',
					optionRoute: 'processes',
					defaultoptionImage: require('@/assets/settings.svg'),
					colouredoptionImage: require('@/assets/settings.svg'),
					displayOptionName: 'Processes'
				},
				{
					optionName: 'configurations',
					optionRoute: 'configurations',
					defaultoptionImage: require('@/assets/settings.svg'),
					colouredoptionImage: require('@/assets/settings.svg'),
					displayOptionName: 'Configurations'
				},
				{
					optionName: 'org-tasks',
					defaultoptionImage: require('@/assets/settings.svg'),
					colouredoptionImage: require('@/assets/settings.svg'),
					optionRoute: 'org-tasks',
					displayOptionName: 'Tasks'
				}
			]
		};
	},
	mounted() {
		let passingRouteName;
        if(this.$route.path.includes('workflows')||this.$route.path.includes('workflow')){
            passingRouteName = 'workflow';
        }else if(this.$route.path.includes('processes')||this.$route.path.includes('process')){
            passingRouteName = 'processes';
        }else if(this.$route.path.includes('configurations')){
            passingRouteName = 'configurations';
        }else if(this.$route.path.includes('tasks')){
            passingRouteName = 'org-tasks';
        }
        this.selectedOptionName = passingRouteName;
        console.log('selectedOptionName', this.selectedOptionName);
		emitter.on('apps-visible', (isToggle: any) => {
			this.appsVisible = isToggle;
		});
		this.getAllTeams()
	},
	computed: {
		...mapGetters({ selectedOptionName: 'val' })
		
	},
	methods: {
		navigate(name:any, routerOption:any) {
			this.$store.dispatch('selectedRoute', name);
			this.$router.push({ name: routerOption });
			this.selectedOptionName = name;
		},
		navigated(teamId:any){
			this.$router.push({ name: 'manage-orgprocesses' ,params:{departmentId:teamId} });

		},
		toggleSideMenuBar() {
			this.isSidebarToggle = !this.isSidebarToggle;
			emitter.emit('toggle-sidebar', this.isSidebarToggle);
		},
		triggerNav(app:string) {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${app == 'monitor' ? process.env.VUE_APP_MONITOR_WEBAPP_URL : process.env.VUE_APP_TRAINING_WEBAPP_URL}`;
		},
		triggerNavToSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ORG_WEBAPP_URL}`;
		},
		triggerNavToAdminSettings() {
			window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
		},
		async getAllTeams() {
						await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
				this.teamsarray = res.data;
				for (var i = 0; i < this.teamsarray.length; i++) {
					this.teams.push({teamName:this.teamsarray[i].teamName,teamOwner:this.teamsarray[i].teamOwner,defaultoptionImage: require('@/assets/settings.svg'),
					colouredoptionImage: require('@/assets/settings.svg'),_id:this.teamsarray[i]['_id']});
				}
			});
			console.log("teams", this.teams)
		}
	},
	
});
</script>